<template>
  <div class="col-md-3 col-lg-2 header-navbar-outer">
    <div class="w-100">
      <div class="navbar-brand-1 me-0">
        <div class="d-flex justify-content-between align-items-center">
          <div class="logo-outer">
            <img src="/assets/images/vc-logo.png" alt="" />
          </div>
          <div class="header-police-logo-outer">
            <div class="header-police-logo-inner">
              <img src="/assets/images/goa-police-logo.png" class="header-goa-logo" alt="goa police" />
              <div class="header-goa-label text-uppercase">goa police</div>
            </div>
          </div>
        </div>
      </div>
      <nav id="sidebarMenu" class="d-md-block collapse nav-navlink-outer">
        <div class="menubar-outer">
          <div class="menubar-submeanu-outer" v-for="(menuItem, headerIndex) in sidebarMenu " :key="headerIndex">
            <ul class="nav flex-column" :id="`header-${headerIndex}`">
              <li class="nav-item">
                <router-link v-if="menuItem.mainMenu" class="nav-link" :to="menuItem.link || ''">
                  <img :src="menuItem.img" class="navlink-tab-icon" alt="" width="16" />{{ menuItem.mainMenu
                  }}</router-link>
                <button v-else-if="menuItem.mainMenuHeader" class="btn btn-toggle align-items-center rounded collapsed"
                  data-bs-toggle="collapse" :data-bs-target="`#${menuItem.mainMenuHeader.replaceAll(' ', '-').replaceAll('/', '-')}`"
                  aria-expanded="false">
                  <span class="mr-auto"><img :src="menuItem.img" class="navlink-tab-icon" alt="" width="16" />{{
                    menuItem.mainMenuHeader }}</span>
                </button>
                <div v-if="menuItem.sidebarSubMenu" class="collapse" data-bs-parent="#sidebarMenu"
                  :id="menuItem.mainMenuHeader.replaceAll(' ', '-').replaceAll('/', '-')">
                  <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                    <li v-for="(subMenuItem, index) in menuItem.sidebarSubMenu" :key="index">
                      <router-link class="nav-link" :to="subMenuItem.link">
                        <div class="submenu-active-span">{{ subMenuItem.submenu }}</div>
                      </router-link>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div class="copyright-outer mt-auto">
        <div class="copyright-inner">
          <div class="pb-2">
            <img class="img-fluid-goa-sidebar w-100" alt="" src="/assets/images/white-GOA-electronics-01-01.png">
          </div>
          <div>
            <img src="/assets/images/login-samrudhh-logo.png" height="50" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      sidebarMenu: [
        {
          mainMenu: "Dashboard",
          link: "/dashboard",
          img: "/assets/images/new-icon/menu-dashboard.svg",
        },
        {
          mainMenuHeader: "Users",
          img: "/assets/images/new-icon/menu-user.svg",
          sidebarSubMenu: [
            {
              submenu: "Add User",
              link: "/users/adduser"
            },
            {
              submenu: "Users List",
              link: "/users/userslist"
            },
            {
              submenu: "Permission Sets",
              link: "/users/permissionsets"
            }

          ]
        },
        {
          mainMenuHeader: "Host",
          img: "/assets/images/new-icon/menu-host.svg",
          sidebarSubMenu: [
            {
              submenu: "Active Host",
              link: "/hosts/active"
            },
            {
              submenu: "Incomplete Host",
              link: "/hosts/incompleted"
            },
            {
              submenu: "Suspended Host",
              link: "/hosts/suspended"
            }
          ]
        },
        {
          mainMenuHeader: "Guests",
          img: "/assets/images/new-icon/menu-guest.svg",
          sidebarSubMenu: [
            {
              submenu: "Current Guests List",
              link: "/guests/currentguests"
            },
            {
              submenu: "Total Guests List",
              link: "/guests/totalguests"
            },
            {
              submenu: "Guests on Monitoring",
              link: "/guests/monitoring"
            },
            {
              submenu: "Guests with Expired Visa",
              link: "/guests/visaexpired"
            },
            {
              submenu: "Guests with Expiring Visa",
              link: "/guests/visaexpering"
            },
            // {
            //   submenu: "Guests Reported By Host/Police",
            //   link: "/guests/reportedby"
            // },
          ]
        },
        {
          mainMenuHeader: "Rent Agreements",
          img: "/assets/images/new-icon/menu-rent.svg",
          sidebarSubMenu: [
            {
              submenu: "Agreements List",
              link: "/agreements/listing"
            },
            {
              submenu: "Nearing Expiry Agreements",
              link: "/agreements/nearingexpiry"
            },
            {
              submenu: "Expired Agreements",
              link: "/agreements/expired"
            },
          ]
        },
        // {
        //   mainMenuHeader: "Law Users",
        //   img: "/assets/images/new-icon/menu-user.svg",
        //   sidebarSubMenu: [
        //     {
        //       submenu: "Add User",
        //       link: "/lawuser/add"
        //     },
        //     {
        //       submenu: "List Users",
        //       link: "/lawuser/listing"
        //     }
        //   ]
        // },
        // {
        //   mainMenuHeader: "Campaign",
        //   img: "/assets/images/new-icon/menu-campaign.svg",
        //   sidebarSubMenu: [
        //     {
        //       submenu: "Campaign List",
        //       link: "/campaign"
        //     },
        //     {
        //       submenu: "Campaign Template",
        //       link: "/campaigntemplates/whatsapp"
        //     },

        //   ]
        // },
        {
          mainMenuHeader: "Cloud Telephony",
          img: "/assets/images/new-icon/menu-cloud.svg",
          sidebarSubMenu: [
            {
              submenu: "Virtual Numbers",
              link: "/cloudtelephony/virtualnumbers"
            },
            {
              submenu: "Custom Tunes",
              link: "/cloudtelephony/custometunes"
            },

          ]
        },
        {
          mainMenuHeader: "Inward Billing",
          img: "/assets/images/new-icon/menu-inward.svg",
          sidebarSubMenu: [
            {
              submenu: "Dashboard",
              link: "/inwardbilling/dashboard"
            },
            {
              submenu: "Tax Invoices",
              link: "/inwardbilling/taxinvoices"
            },
            {
              submenu: "Account Statement",
              link: "/inwardbilling/accountstatement"
            },
            {
              submenu: "Payment Receipts",
              link: "/inwardbilling/paymentreceipts"
            },
            {
              submenu: "Billing Company",
              link: "/inwardbilling/billingcompany"
            },
          ]
        },
        {
          mainMenuHeader: "Outward Billing",
          img: "/assets/images/new-icon/menu-outward.svg",
          sidebarSubMenu: [

            {
              submenu: "Dashboard",
              link: "/outwardbilling/dashboard"
            },
            {
              submenu: "Tax Invoices",
              link: "/outwardbilling/taxinvoices"
            },
            {
              submenu: "Account Statement",
              link: "/outwardbilling/accountstatement"
            },
            {
              submenu: "Payment Receipts",
              link: "/outwardbilling/paymentreceipts"
            },
            // {
            //   submenu: "Live Balances",
            //   link: "/outwardbilling/livebalances"
            // },
          ]
        },
        {
          mainMenuHeader: "System Logs",
          img: "/assets/images/icon/system-logs-menu-icon.svg",
          sidebarSubMenu: [
            {
              submenu: "Sms Logs",
              link: "/logs/sms"
            },
            {
              submenu: "Whatsapp Logs",
              link: "/logs/whatsapplogs"
            },
            {
              submenu: "Web Bot Logs",
              link: "/logs/webbotlogs"
            },
            {
              submenu: "Email Logs",
              link: "/logs/email"
            },
            {
              submenu: "Incomming Call Logs",
              link: "/logs/incoming"
            },
            {
              submenu: "Outgoing Call Logs",
              link: "/logs/outgoing"
            },
            {
              submenu: "Aadhar Logs",
              link: "/logs/aadhar-v1-card"
            },
            {
              submenu: "DL Logs",
              link: "/logs/driving-licence-logs"
            },
            {
              submenu: "PassPort Logs",
              link: "/logs/passportlogs"
            },
            {
              submenu: "Import Logs",
              link: "/logs/import"
            },
            {
              submenu: "RTO Logs",
              link: "/logs/rtologs"
            },
            {
              submenu: "PAN Logs",
              link: "/logs/panogs"
            },
            {
              submenu: "Nepali PAN Logs",
              link: "/logs/nepalipanogs"
            },
            {
              submenu: "Nepali Nagrikta Logs",
              link: "/logs/nepalinagriktaogs"
            },
            {
              submenu: "Emirates Id Logs",
              link: "/logs/emiratesidlogs"
            },
          ]
        },
        {
          mainMenu: "Support",
          link: "/support/new",
          img: "/assets/images/icon/support-menu-icon.svg",
        }, 
        {
          mainMenuHeader: "Settings",
          img: "/assets/images/icon/setting-menu-icon.svg",
          sidebarSubMenu: [
            {
              submenu: "Charges Settings",
              link: "/settings/chargessetting"
            },
            {
              submenu: "Guests Record Settings",
              link: "/settings/guestrecordsetting"
            },
            {
              submenu: "Host Settings",
              link: "/settings/hostsetting"
            },
            {
              submenu: "Invoice Settings",
              link: "/settings/invoice"
            }
          ]
        },
      ]
    };
  },
};
</script>


<style scoped>
.disabled-link {
  pointer-events: none;
  /* Prevents clicking */
}
</style>
