<template>
    <div class="row">
        <div class="col-lg-12 col-md-12 col-12">
            <div class="custom-form-group">
                <label class="intelligent-campagin-modal-header">Push Notifications Campaign</label>
            </div>
        </div>
    </div>
    <div class="campaign-tab-outer-scroll" :style="{ height: '377px' }">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-12">
                <div class="custom-form-group">
                    <label class="form-label">Template Name <span class="text-danger">*</span></label>
                    <input type="text" v-model="templatename" class="form-control" placeholder="Enter Template Name" maxlength="512" autocomplete="off"
                        v-on:keyup="checkTemplateName()" />
                    <div class="text-muted text-end" v-if="templatename.length != 0">{{ templatenameRemainCountTotal }}/512</div>
                    <div class="custom-error" v-if="v$.templatename.$error"> {{ v$.templatename.$errors[0].$message }} </div>
                    <div class="custom-error" v-if="duplicateTemplateMsg"> {{ duplicateTemplateMsg }} </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-8 col-md-8 col-12">
                <div class="custom-form-group">
                    <label class="form-label">Template Alias Name <span class="text-danger">*</span></label>
                    <input type="text" v-model="templatealiasname" class="form-control" placeholder="Enter Template Alias Name" maxlength="512"
                        autocomplete="off" />
                    <div class="custom-error" v-if="v$.templatealiasname.$error"> {{ v$.templatealiasname.$errors[0].$message }} </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-4 col-12">
                <div class="custom-form-group">
                    <label class="form-label">Choose Language<span class="text-danger">*</span></label>
                    <MultiSelect v-model="languagevalue" :options="languagelist" :searchable="true" label="label" placeholder="Select Language"
                        class="multiselect-custom text-capitalize" :canClear="false" :closeOnSelect="true" noOptionsText="No Result Found" :object="true" />
                    <div class="custom-error" v-if="v$.languagevalue.$error"> {{ v$.languagevalue.$errors[0].$message }} </div>
                </div>
            </div>
        </div>
        <div class="row" v-if="languagevalue != ''">
            <div class="col-lg-8 col-md-8 col-12">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-12">
                        <div class="card-template">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div class="custom-form-group">
                                        <label class="form-label">Notification Title Name</label>
                                        <input type="text" v-model="titlename" class="form-control" placeholder="Enter Notification Title Name" maxlength="70"
                                            autocomplete="off" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-template mt-3">
                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-12">
                                    <div class="custom-form-group">
                                        <label class="form-label">Header<span class="text-danger">*</span></label>
                                        <div class="custom-group-radio-box-btn">
                                            <div class="form-check form-check-inline custom-radio-check-form">
                                                <input class="form-check-input custom-radio-btn" v-model="headertype" type="radio" name="none-option"
                                                    id="headernone" value="0" @click="clearheader()" />
                                                <label class="form-check-label" for="headernone">None</label>
                                            </div>
                                            <div class="form-check form-check-inline custom-radio-check-form">
                                                <input class="form-check-input custom-radio-btn" v-model="headertype" type="radio" name="text-option"
                                                    id="headermedia" value="1" @click="clearheader()" />
                                                <label class="form-check-label" for="headermedia">Media</label>
                                            </div>
                                        </div>
                                        <div class="custom-error" v-if="v$.headertype.$error"> {{ v$.headertype.$errors[0].$message }} </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-12" v-if="headertype == 1">
                                    <div class="custom-form-group">
                                        <label class="form-label">Upload Image <span class="text-danger">*</span></label>
                                        <FileUpload mode="basic" accept="image/*" :auto="true" ref="whtheaderimage" class="custom-upload-btn-ultima"
                                            v-model="whtheaderimage" v-on:change="handleWhtImageUploader()" />
                                        <div class="custom-error" v-if="v$.whtheaderimage.$error"> {{ v$.whtheaderimage.$errors[0].$message }} </div>
                                        <div class="custom-error" v-if="errorwhtheaderimage">{{ errorwhtheaderimage }}</div>
                                        <div class="text-primary mt-1" v-if="exitwhatheaderpreviewimgurl != null">{{ exitwhatheaderpreviewimgurl.name }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-template mt-3">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div class="custom-form-group">
                                        <label for="formmessageInput" class="form-label">Message</label>
                                        <textarea type="text" id="formmessageInput" v-model="notificationmessage" class="form-control custom-text-area-scroll"
                                            placeholder="Enter Message" v-on:keyup="htmlconvertmsg()" autocomplete="off" rows="3" maxlength="250"
                                            :style="{ resize: 'vertical' }"></textarea>
                                        <div class="custom-error" v-if="v$.notificationmessage.$error"> {{ v$.notificationmessage.$errors[0].$message }} </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-12">
                                <div class="custom-form-group text-center">
                                    <button type="button" class="btn custom-form-group-btn" @click="pushnotificationsubmitbtn()"
                                        :disabled="notificationbtnloader" style="width: 150px;">
                                        <span v-if="!notificationbtnloader">Submit</span>
                                        <div class="spinner-border text-light custom-spinner-loader-btn" role="status" v-if="notificationbtnloader">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-4 col-12">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-12">
                        <div class="intelligent-campagin-modal-header mb-2 text-center">Message Preview</div>
                    </div>
                </div>
                <div class="preview-card-template">
                    <div class="mb-2" v-if="titlename"> {{ this.titlename }} </div>
                    <div v-if="headertype == 1">
                        <img v-if="whtheaderimage != null" :src="exitwhatheaderpreviewimgurl.objectURL" :style="{ width: '100%' }" alt="" />
                        <img v-if="whtheaderimage == null" src="/assets/images/preview-img.jpg" alt="" :style="{ width: '100%' }" />
                    </div>
                    <div class="preview-body-msg" v-if="notificationmessage != ''">
                        <p v-html="this.whatsappmessageinput"></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ApiService from "../../service/ApiService";
import useValidate from "@vuelidate/core";
import { required, requiredIf, helpers } from "@vuelidate/validators";
import { useToast } from 'vue-toast-notification';
export default {
    data () {
        const toast = useToast();
        return {
            v$: useValidate(),
            toast: toast,
            notificationbtnloader: false,
            templatename: '',
            templatealiasname: '',
            titlename: '',
            languagevalue: '',
            headertype: '',
            whtheaderimage: null,
            errorwhtheaderimage: '',
            exitwhatheaderpreviewimgurl: null,

            notificationmessage: '',
            languagelist: [],
            templatenameMxLimitCount: 250,
            templatenameRemainCountTotal: 250,
            templatebodyMxLimitCount: 250,
            templatebodyRemainCountTotal: 250,
            errStatus: false,
            errmsg: '',
            whatsappmessageinput: '',
            duplicateTemplateMsg: '',
        };
    },
    validations () {
        return {
            templatename: {
                required: helpers.withMessage( "Please enter template name", required ),
            },
            templatealiasname: {
                required: helpers.withMessage( "Please enter template name", required ),
            },
            languagevalue: {
                required: helpers.withMessage( "Please select language", required ),
            },
            headertype: {
                required: helpers.withMessage( "Please select header", required ),
            },
            whtheaderimage: {
                requiredIf: helpers.withMessage(
                    "Please upload image",
                    requiredIf(
                        this.headertype == 1
                    )
                ),
            },
            notificationmessage: {
                requiredIf: helpers.withMessage(
                    "Please enter message",
                    requiredIf(
                        this.headertype == 0 && this.headertype != ''
                    )
                ),
            },
        };
    },
    ApiService: null,

    created () {
        this.ApiService = new ApiService();
    },
    mounted () {
        this.getlanguages();
    },
    methods: {
        getlanguages ( e ) {
            this.ApiService.getlanguages( e ).then( ( data ) => {
                if ( data.status == 200 ) {
                    this.languagelist = data.data;
                } else {
                    this.languagelist = [];
                }
            } );
        },
        showTemplateCountDown: function () {
            this.templatenameRemainCountTotal = this.templatenameMxLimitCount - this.templatename.length;
        },
        showTemplateBodyCountDown: function () {
            this.templatebodyRemainCountTotal = this.templatebodyMxLimitCount - this.notificationmessage.length;
        },
        pushnotificationsubmitbtn () {
            this.v$.$validate();
            var formData = new FormData();
            formData.append( "cc2", this.templatealiasname );
            formData.append( "cc3", this.templatename );
            formData.append( "cc17", this.titlename );
            if ( this.languagevalue ) {
                formData.append( 'cc6', this.languagevalue.value );
                formData.append( 'cc7', this.languagevalue.label );
            }
            formData.append( "cc9", this.headertype );
            if ( this.whtheaderimage ) {
                formData.append( "cc11", this.whtheaderimage );
            }
            formData.append( "cc5", this.notificationmessage );
            if ( !this.v$.$error && !this.duplicateTemplateMsg ) {
                this.notificationbtnloader = true;
                this.ApiService.addnotificationcampaignTemplate( formData ).then( ( data ) => {
                    if ( data.status == 200 ) {
                        this.notificationbtnloader = false;
                        this.$store.dispatch( "addCampaignStatus" );
                        var successMsg = data.message;
                        this.toast.open( {
                            message: successMsg,
                            type: "success",
                            duration: 3000,
                            position: "top-right",
                        } );
                        setTimeout( () => {
                            this.v$.$reset();
                        }, 0 );
                        this.redirectcampaigntemplate();
                    } else {
                        this.notificationbtnloader = false;
                        var errorMsg = data.message;
                        this.toast.open( {
                            message: errorMsg,
                            type: "error",
                            duration: 3000,
                            position: "top-right",
                        } );
                    }
                } ).catch( ( error ) => {
                    console.log( error )
                } )

            } else {
                this.$error;
            }
        },
        handleWhtImageUploader () {
            var file = this.$refs.whtheaderimage.files[ 0 ];
            let allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
            if ( !file ) {
                this.errorwhtheaderimage = 'Please upload image';
            } else if ( !file.size > 2048 ) {
                this.errorwhtheaderimage = 'File size cannot exceed more than 2MB';
            } else if ( !file.name.match( allowedExtensions ) ) {
                this.errorwhtheaderimage = 'Invalid file type';
            } else {
                this.errorwhtheaderimage = '';
                this.whtheaderimage = this.$refs.whtheaderimage.files[ 0 ];
                this.exitwhatheaderpreviewimgurl = this.whtheaderimage;
            }
        },
        clearheader () {
            this.whtheaderimage = null;
            this.errorwhtheaderimage = '';
            this.exitwhatheaderpreviewimgurl = null;
        },
        redirectcampaigntemplate () {
            window.location.href = "/campaigntemplates/pushnotification";
        },
        htmlconvertmsg () {
            var messagevalue = this.notificationmessage;
            this.whatsappmessageinput = messagevalue.replace( /\n/g, '<br/>' );
        },

        checkTemplateName () {
            let pushNotificationTemplates = this.$store.state.campaign.pushNotificationTemplates
            if ( this.templatename.length > 3 ) {
                for ( var i = 0, len = pushNotificationTemplates.length; i < len; i++ ) {
                    if ( pushNotificationTemplates[ i ][ 'cc3' ].toLowerCase().trim() === this.templatename.toLowerCase() ) {
                        this.duplicateTemplateMsg = 'Template name already exits.';
                        break;
                    } else {
                        this.duplicateTemplateMsg = '';
                    }
                }
            }
        }
    },
};
</script>
<style scoped>
.campaign-modal-outer-border {
    padding: 15px;
    border: 1px solid #dee2e6;
    border-top: 0;
}

.intelligent-campagin-modal-header {
    font-family: 'OpenSans-Regular';
    font-size: 16px;
    letter-spacing: 0.52px;
    line-height: 18px;
    margin-top: 10px;
    color: #0C1755;
    font-weight: 600;
    text-align: center;
}

.campaign-tab-outer-scroll {
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
}

.campaign-tab-outer-scroll::-webkit-scrollbar {
    display: block;
}

.campaign-tab-outer-scroll::-webkit-scrollbar {
    width: 0.3em;
    height: 0.3em;
}

.campaign-tab-outer-scroll::-webkit-scrollbar-thumb {
    background-color: rgb(113 115 119 / 49%);
    border-radius: 3px;
}

.card-template {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #c1d4f3;
    border-radius: 4px;
    padding: 14px 15px 0px 15px;
}

.preview-card-template {
    border: 1px solid #c1d4f3;
    border-radius: 6px;
    padding: 14px 15px 14px 15px;
    height: auto;
    min-height: 197px;
    background: #E1EEFF;
}

.preview-body-msg {
    font-size: 12px;
    letter-spacing: 0.28px;
    line-height: 15px;
    margin-top: 15px;
    font-family: 'OpenSans-Regular';
    color: #272727;
}

.preview-footer-msg {
    margin-top: 15px;
}
</style>