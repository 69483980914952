import { createRouter, createWebHistory } from 'vue-router';
import store from './store'
import commonFunction from "./composables/commonFunction"
const { checkEmpty } = commonFunction()
const routes = [

  {
    path: "/host-registration",
    name: "Host Registration",
    exact: true,
    component: () => import( "./pages/NewHostRegistration/views/HostLayout.vue" ),
    meta: {
      requiresAuth: false,
    },
  },

  {
    path: "/",
    name: "Home",
    exact: true,
    component: () => import( "./pages/Login.vue" ),
    meta: {
      requiresAuth: false,
    },
  },

  {
    path: "/login",
    name: "Login",
    exact: true,
    component: () => import( "./pages/Login.vue" ),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/forgotpassword",
    name: "ForgotPassword",
    exact: true,
    component: () => import( "./pages/ForgotPassword.vue" ),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    exact: true,
    component: () => {
      if ( window.userType == 2 ) {
        return import( "./pages/hostmodule/Dashboard.vue" );
      } else if ( window.userType == 1 ) {
        return import( "./pages/adminmodule/Dashboard.vue" );
      }
      else if ( window.userType == 3 ) {
        return import( "./pages/policemodule/Dashboard.vue" );
      }
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/visitor/add",
    name: "AddGuest",
    component: () => import( "./pages/hostmodule/guests/AddGuest.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/visitors/totalvisitor",
    name: "ListVisitor",
    component: () => import( "./pages/hostmodule/guests/ListGuests.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/guests/totalguests",
    name: "ListGuests",
    component: () => {
      if ( window.userType == 1 ) {
        return import( "./pages/adminmodule/guests/ListGuests.vue" );
      } else if ( window.userType == 3 ) {
        return import( "./pages/policemodule/guests/ListGuests.vue" );
      }
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/visitors/current-visitor",
    name: "CurrentVisitor",
    component: () => import( "./pages/hostmodule/guests/CurrentGuest.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/guests/currentguests",
    name: "CurrentGuests",
    component: () => {
      if ( window.userType == 1 ) {
        return import( "./pages/adminmodule/guests/CurrentGuest.vue" );
      } else if ( window.userType == 3 ) {
        return import( "./pages/policemodule/guests/CurrentGuest.vue" );
      }
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/visitors/reports",
    name: "VisitorReport",
    component: () => import( "./pages/hostmodule/guests/GuestsReport.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/guests/reports",
    name: "GuestsReport",
    component: () => {
      if ( window.userType == 1 ) {
        return import( "./pages/adminmodule/guests/GuestsReport.vue" );
      } else if ( window.userType == 3 ) {
        return import( "./pages/policemodule/guests/GuestsReport.vue" );
      }
    },
    meta: {
      requiresAuth: true,
    },
  },
  
  {
    path: "/visitors/addreport",
    name: "AddGuestsReport",
    component: () => import( "./pages/hostmodule/guests/AddGuestReport.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/guests/monitoring",
    name: "GuestOnMonitoring",
    component: () => {
      if ( window.userType == 1 ) {
        return import( "./pages/adminmodule/guests/GuestOnMonitoring.vue" );
      } else if ( window.userType == 3 ) {
        return import( "./pages/policemodule/guests/GuestOnMonitoring.vue" );
      }
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/guests/visaexpering",
    name: "ExperingVisaGuests",
    component: () => {
      if ( window.userType == 1 ) {
        return import( "./pages/adminmodule/guests/ExperingVisaGuests.vue" );
      } else if ( window.userType == 3 ) {
        return import( "./pages/policemodule/guests/ExperingVisaGuests.vue" );
      }
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/guests/visaexpired",
    name: "ExpiredVisaGuests",
    component: () => {
      if ( window.userType == 1 ) {
        return import( "./pages/adminmodule/guests/ExpiredVisaGuests.vue" );
      } else if ( window.userType == 3 ) {
        return import( "./pages/policemodule/guests/ExpiredVisaGuests.vue" );
      }
    },
    meta: {
      requiresAuth: true,
    },
  },
  // {
  //   path: "/guests/reportedby",
  //   name: "GuestsReportedby",
  //   component: () => import( "./pages/adminmodule/guests/GuestReportedByHostOrPolice.vue" ),
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  {
    path: "/agreements/listing",
    name: "RentAgreementsList",
    component: () => import( "./pages/adminmodule/agreements/RentAgreementsList.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/agreements/nearingexpiry",
    name: "RentAgreementsNearExpiry",
    component: () => import( "./pages/adminmodule/agreements/RentAgreementsNearExpiry.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/agreements/expired",
    name: "RentAgreementsExpired",
    component: () => import( "./pages/adminmodule/agreements/RentAgreementsExpired.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/agreements/representative",
    name: "OwnerRepresentative",
    component: () => import( "./pages/adminmodule/agreements/OwnerRepresentative.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/agreements/owners",
    name: "PropertyOwnerList",
    component: () => import( "./pages/adminmodule/agreements/PropertyOwnerList.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/agreements/tenant",
    name: "RentAgreementTenant",
    component: () => import( "./pages/adminmodule/agreements/RentAgreementTenant.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/outwardbilling/dashboard",
    name: "OutwardBillingDashboard",
    component: () => {
      if ( window.userType == 1 ) {
        return import( "./pages/adminmodule/OutwardBilling/Dashboard.vue" );
      } else if ( window.userType == 3 ) {
        return import( "./pages/policemodule/OutwardBilling/Dashboard.vue" );
      }
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/outwardbilling/taxinvoices",
    name: "OutwardBillingTaxInvoice",
    component: () => {
      if ( window.userType == 1 ) {
        return import( "./pages/adminmodule/OutwardBilling/TaxInvoice.vue" );
      } else if ( window.userType == 3 ) {
        return import( "./pages/policemodule/OutwardBilling/TaxInvoice.vue" );
      }
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/outwardbilling/accountstatement",
    name: "OutwardBillingAccountStatement",
    component: () => {
      if ( window.userType == 1 ) {
        return import( "./pages/adminmodule/OutwardBilling/AccountStatement.vue" );
      } else if ( window.userType == 3 ) {
        return import( "./pages/policemodule/OutwardBilling/AccountStatement.vue" );
      }
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/outwardbilling/paymentreceipts",
    name: "OutwardBillingPaymentReceipt",
    component: () => {
      if ( window.userType == 1 ) {
        return import( "./pages/adminmodule/OutwardBilling/PaymentReceipt.vue" );
      } else if ( window.userType == 3 ) {
        return import( "./pages/policemodule/OutwardBilling/PaymentReceipt.vue" );
      }
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/billing/statement",
    name: "BillingAccountStatement",
    component: () => import( "./pages/hostmodule/billing/AccountStatement.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/billing/receipt",
    name: "BillingPaymentReceipt",
    component: () => import( "./pages/hostmodule/billing/PaymentReceipt.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/billing/invoice",
    name: "BillingTaxInvoice",
    component: () => import( "./pages/hostmodule/billing/TaxInvoice.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/billing/dashboard",
    name: "BillingDashboard",
    component: () => import( "./pages/hostmodule/billing/Dashboard.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/outwardbilling/livebalances",
    name: "LiveBalances",
    component: () => {
      if ( window.userType == 1 ) {
        return import( "./pages/adminmodule/OutwardBilling/LiveBalances.vue" );
      } else if ( window.userType == 3 ) {
        return import( "./pages/policemodule/OutwardBilling/LiveBalances.vue" );
      }
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/billing/tds",
    name: "BillingPaymentTDS",
    component: () => import( "./pages/hostmodule/billing/TDSPayment.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/billing/changeplanrequest",
    name: "ChangePlanRequest",
    component: () => import( "./pages/hostmodule/billing/ChangePlanRequest.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  
  {
    path: "/inwardbilling/dashboard",
    name: "BillingDashboardAdmin",
    component: () => {
      if ( window.userType == 1 ) {
        return import( "./pages/adminmodule/InwardBilling/Dashboard.vue" );
      } else if ( window.userType == 3 ) {
        return import( "./pages/policemodule/InwardBilling/Dashboard.vue" );
      }
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/inwardbilling/taxinvoices",
    name: "BillingTaxInvoiceAdmin",
    component: () => {
      if ( window.userType == 1 ) {
        return import( "./pages/adminmodule/InwardBilling/TaxInvoice.vue" );
      } else if ( window.userType == 3 ) {
        return import( "./pages/policemodule/InwardBilling/TaxInvoice.vue" );
      }
    },
    meta: {
      requiresAuth: true,
    },
  },
  
  {
    path: "/inwardbilling/paymentreceipts",
    name: "BillingPaymentReceiptAdmin",
    component: () => {
      if ( window.userType == 1 ) {
        return import( "./pages/adminmodule/InwardBilling/PaymentReceipt.vue" );
      } else if ( window.userType == 3 ) {
        return import( "./pages/policemodule/InwardBilling/PaymentReceipt.vue" );
      }
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/inwardbilling/accountstatement",
    name: "BillingAccountStatementAdmin",
    component: () => {
      if ( window.userType == 1 ) {
        return import( "./pages/adminmodule/InwardBilling/AccountStatement.vue" );
      } else if ( window.userType == 3 ) {
        return import( "./pages/policemodule/InwardBilling/AccountStatement.vue" );
      }
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/inwardbilling/billingcompany",
    name: "adminBillingCompany",
    component: () => {
      if ( window.userType == 1 ) {
        return import( "./pages/adminmodule/InwardBilling/BillingCompany.vue" );
      } else if ( window.userType == 3 ) {
        return import( "./pages/policemodule/InwardBilling/BillingCompany.vue" );
      }
    },
    meta: {
      requiresAuth: true,
    },
  },
  
  
  // Super Admin Routes//
  {
    path: "/users/adduser",
    name: "NewUser",
    component: () => {
      if ( window.userType == 2 ) {
        return import( "./pages/hostmodule/Users/AddUser.vue" );
      } else if ( window.userType == 1 ) {
        return import( "./pages/adminmodule/Users/AddUser.vue" );
      } else if ( window.userType == 3 ) {
        return import( "./pages/policemodule/Users/AddUser.vue" );
      }
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/users/userslist",
    name: "UserListing",
    component: () => {
      if ( window.userType == 2 ) {
        return import( "./pages/hostmodule/Users/ListUsers.vue" );
      } else if ( window.userType == 1 ) {
        return import( "./pages/adminmodule/Users/ListUsers.vue" );
      } else if ( window.userType == 3 ) {
        return import( "./pages/policemodule/Users/ListUsers.vue" );
      }
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/users/permissionsets",
    name: "UserRole",
    component: () => {
      if ( window.userType == 2 ) {
        return import( "./pages/hostmodule/Users/UsersRole.vue" );
      } else if ( window.userType == 1 ) {
        return import( "./pages/adminmodule/Users/UsersRole.vue" );
      } else if ( window.userType == 3 ) {
        return import( "./pages/policemodule/Users/UsersRole.vue" );
      }
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/users/importusers",
    name: "BulkImport",
    component: () => {
      if ( window.userType == 2 ) {
        return import( "./pages/hostmodule/Users/bulkImport.vue" );
      } else if ( window.userType == 3 ) {
        return import( "./pages/policemodule/Users/bulkImport.vue" );
      }
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/branchlist",
    name: "BranchList",
    component: () => import( "./pages/hostmodule/BranchList.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/support/new",
    name: "NewTicket",
    component: () => {
      if ( window.userType == 2 ) {
        return import( "./pages/hostmodule/support/NewTicket.vue" );
      } else if ( window.userType == 1 ) {
        return import( "./pages/adminmodule/support/NewTicket.vue" );
      } else if ( window.userType == 3 ) {
        return import( "./pages/policemodule/support/NewTicket.vue" );
      }
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/support/open",
    name: "OpenTicket",
    component: () => {
      if ( window.userType == 2 ) {
        return import( "./pages/hostmodule/support/OpenTicket.vue" );
      } else if ( window.userType == 1 ) {
        return import( "./pages/adminmodule/support/OpenTicket.vue" );
      } else if ( window.userType == 3 ) {
        return import( "./pages/policemodule/support/OpenTicket.vue" );
      }
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/support/closed",
    name: "ClosedTicket",
    component: () => {
      if ( window.userType == 2 ) {
        return import( "./pages/hostmodule/support/ClosedTicket.vue" );
      } else if ( window.userType == 1 ) {
        return import( "./pages/adminmodule/support/ClosedTicket.vue" );
      } else if ( window.userType == 3 ) {
        return import( "./pages/policemodule/support/ClosedTicket.vue" );
      }
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/support/cancelled",
    name: "CancelledTicket",
    component: () => {
      if ( window.userType == 2 ) {
        return import( "./pages/hostmodule/support/CancelledTicket.vue" );
      } else if ( window.userType == 1 ) {
        return import( "./pages/adminmodule/support/CancelledTicket.vue" );
      } else if ( window.userType == 3 ) {
        return import( "./pages/policemodule/support/CancelledTicket.vue" );
      }
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/support/total",
    name: "TotalTicket",
    component: () => {
      if (window.userType == 2) {
        return import("./pages/hostmodule/support/TotalTicket.vue");
      } else if (window.userType == 1) {
        return import("./pages/adminmodule/support/TotalTicket.vue");
      } else if ( window.userType == 3 ) {
        return import( "./pages/policemodule/support/TotalTicket.vue" );
      }
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/myprofile",
    name: "MyProfile",
    component: () => import( "./pages/MyProfile.vue" ),

    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/campaignslist",
    name: "CampaignList",
    component: () => {
      if (window.userType == 1) {
        return import("./pages/adminmodule/campaign/CampaignList.vue");
      } else if ( window.userType == 3 ) {
        return import( "./pages/policemodule/campaign/CampaignList.vue" );
      }
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/campaignslist/whatsapplogs/:campaignId?',
    name: 'WhatsAppCampaignLogs',
      component: () => {
        if (window.userType == 1) {
          return import("./pages/adminmodule/campaign/WhatsAppCampaignLogs.vue");
        } else if ( window.userType == 3 ) {
          return import( "./pages/policemodule/campaign/WhatsAppCampaignLogs.vue" );
        }
      },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/campaignslist/smslogs/:campaignId?',
    name: 'SmsCampaignLogs',
    component: () => {
      if (window.userType == 1) {
        return import("./pages/adminmodule/campaign/SmsCampaignLogs.vue");
      } else if ( window.userType == 3 ) {
        return import( "./pages/policemodule/campaign/SmsCampaignLogs.vue" );
      }
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/campaignslist/calllogs/:campaignId?',
    name: 'CallCampaignLogs',
    component: () => {
      if (window.userType == 1) {
        return import("./pages/adminmodule/campaign/CallCampaignLogs.vue");
      } else if ( window.userType == 3 ) {
        return import( "./pages/policemodule/campaign/CallCampaignLogs.vue" );
      }
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/campaignslist/pushlogs/:campaignId?',
    name: 'PushCampaignLogs',
    component: () => {
      if (window.userType == 1) {
        return import("./pages/adminmodule/campaign/PushCampaignLogs.vue");
      } else if ( window.userType == 3 ) {
        return import( "./pages/policemodule/campaign/PushCampaignLogs.vue" );
      }
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/campaignslist/logs/:campaignId?",
    name: "CampaignLogs",
    component: () => {
      if (window.userType == 1) {
        return import("./pages/adminmodule/campaign/CampaignLogs.vue");
      } else if ( window.userType == 3 ) {
        return import( "./pages/policemodule/campaign/CampaignLogs.vue" );
      }
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/campaignslist/report/:campaignId?',
    name: 'CallCampaignReports',
    component: () => {
      if (window.userType == 1) {
        return import("./pages/adminmodule/campaign/CallCampaignReports.vue");
      } else if ( window.userType == 3 ) {
        return import( "./pages/policemodule/campaign/CallCampaignReports.vue" );
      }
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/campaignslist/report/:campaignId?',
    name: 'SmsCampaignReports',
    component: () => {
      if (window.userType == 1) {
        return import("./pages/adminmodule/campaign/SmsCampaignReports.vue");
      } else if ( window.userType == 3 ) {
        return import( "./pages/policemodule/campaign/SmsCampaignReports.vue" );
      }
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/campaignslist/report/:campaignId?",
    name: "CampaignReports",
    component: () => {
      if (window.userType == 1) {
        return import("./pages/adminmodule/campaign/CampaignReports.vue");
      } else if ( window.userType == 3 ) {
        return import( "./pages/policemodule/campaign/CampaignReports.vue" );
      }
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/campaigntemplates/intelligent',
    name: 'IntelligentCampaignListing',
    component: () => {
      if (window.userType == 1) {
        return import("./pages/adminmodule/campaigntemplatelist/IntelligentCampaignListing.vue");
      } else if ( window.userType == 3 ) {
        return import( "./pages/policemodule/campaigntemplatelist/IntelligentCampaignListing.vue" );
      }
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/campaigntemplates/whatsapp',
    name: 'WhatsAppCampaignListing',
    component: () => {
      if (window.userType == 1) {
        return import("./pages/adminmodule/campaigntemplatelist/WhatsAppCampaignListing.vue");
      } else if ( window.userType == 3 ) {
        return import( "./pages/policemodule/campaigntemplatelist/WhatsAppCampaignListing.vue" );
      }
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/campaigntemplates/rcsimessage',
    name: 'iMessageCampaignListing',
    component: () => {
      if (window.userType == 1) {
        return import("./pages/adminmodule/campaigntemplatelist/iMessageCampaignListing.vue");
      } else if ( window.userType == 3 ) {
        return import( "./pages/policemodule/campaigntemplatelist/iMessageCampaignListing.vue" );
      }
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/campaigntemplates/sms',
    name: 'SMSCampaignListing',
    component: () => {
      if (window.userType == 1) {
        return import("./pages/adminmodule/campaigntemplatelist/SMSCampaignListing.vue");
      } else if ( window.userType == 3 ) {
        return import( "./pages/policemodule/campaigntemplatelist/SMSCampaignListing.vue" );
      }
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/campaigntemplates/email',
    name: 'EmailCampaignListing',
    component: () => {
      if (window.userType == 1) {
        return import("./pages/adminmodule/campaigntemplatelist/EmailCampaignListing.vue");
      } else if ( window.userType == 3 ) {
        return import( "./pages/policemodule/campaigntemplatelist/EmailCampaignListing.vue" );
      }
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/campaigntemplates/audiocall',
    name: 'AudioCallCampaignListing',
    component: () => {
      if (window.userType == 1) {
        return import("./pages/adminmodule/campaigntemplatelist/AudioCallCampaignListing.vue");
      } else if ( window.userType == 3 ) {
        return import( "./pages/policemodule/campaigntemplatelist/AudioCallCampaignListing.vue" );
      }
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/campaigntemplates/pushnotification',
    name: 'PushNotificationCampaignListing',
    component: () => {
      if (window.userType == 1) {
        return import("./pages/adminmodule/campaigntemplatelist/PushNotificationCampaignListing.vue");
      } else if ( window.userType == 3 ) {
        return import( "./pages/policemodule/campaigntemplatelist/PushNotificationCampaignListing.vue" );
      }
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/logs/sms",
    name: "SMSLogs",
    component: () => {
      if (window.userType == 1) {
        return import("./pages/adminmodule/systemlogs/SmsLogs.vue");
      } else if ( window.userType == 3 ) {
        return import( "./pages/policemodule/systemlogs/SmsLogs.vue" );
      }
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/logs/whatsapplogs",
    name: "WhatsAppLogs",
    component: () => {
      if (window.userType == 1) {
        return import("./pages/adminmodule/systemlogs/WhatsAppLogs.vue");
      } else if ( window.userType == 3 ) {
        return import( "./pages/policemodule/systemlogs/WhatsAppLogs.vue" );
      }
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/logs/webbotlogs",
    name: "WebBotLogs",
    component: () => {
      if (window.userType == 1) {
        return import("./pages/adminmodule/systemlogs/WebBotLogs.vue");
      } else if ( window.userType == 3 ) {
        return import( "./pages/policemodule/systemlogs/WebBotLogs.vue" );
      }
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/logs/email",
    name: "EmailLogs",
    component: () => {
      if (window.userType == 1) {
        return import("./pages/adminmodule/systemlogs/EmailLogs.vue");
      } else if ( window.userType == 3 ) {
        return import( "./pages/policemodule/systemlogs/EmailLogs.vue" );
      }
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/logs/incoming",
    name: "IncomingLogs",
    component: () => {
      if (window.userType == 1) {
        return import("./pages/adminmodule/systemlogs/MyIncomingLogs.vue");
      } else if ( window.userType == 3 ) {
        return import( "./pages/policemodule/systemlogs/MyIncomingLogs.vue" );
      }
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/logs/outgoing",
    name: "OutgoingLogs",
    component: () => {
      if (window.userType == 1) {
        return import("./pages/adminmodule/systemlogs/MyOutgoingLogs.vue");
      } else if ( window.userType == 3 ) {
        return import( "./pages/policemodule/systemlogs/MyOutgoingLogs.vue" );
      }
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/logs/driving-licence-logs",
    name: "DrivingLicenceLogs",
    component: () => {
      if (window.userType == 1) {
        return import("./pages/adminmodule/systemlogs/DrivingLicenceLogs.vue");
      } else if ( window.userType == 3 ) {
        return import( "./pages/policemodule/systemlogs/DrivingLicenceLogs.vue" );
      }
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/logs/passportlogs",
    name: "PassportLogs",
    component: () => {
      if (window.userType == 1) {
        return import("./pages/adminmodule/systemlogs/PassportLogs.vue");
      } else if ( window.userType == 3 ) {
        return import( "./pages/policemodule/systemlogs/PassportLogs.vue" );
      }
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/logs/aadhar-v1-card",
    name: "AadharV1Logs",
    component: () => {
      if (window.userType == 1) {
        return import("./pages/adminmodule/systemlogs/AadharV1Logs.vue");
      } else if ( window.userType == 3 ) {
        return import( "./pages/policemodule/systemlogs/AadharV1Logs.vue" );
      }
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/logs/aadhar-v2-card",
    name: "AadharV2Logs",
    component: () => {
      if (window.userType == 1) {
        return import("./pages/adminmodule/systemlogs/AadharV2Logs.vue");
      } else if ( window.userType == 3 ) {
        return import( "./pages/policemodule/systemlogs/AadharV2Logs.vue" );
      }
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/logs/import",
    name: "ImportGuestLogs",
    component: () => {
      if (window.userType == 1) {
        return import("./pages/adminmodule/systemlogs/ImportGuestLogs.vue");
      } else if ( window.userType == 3 ) {
        return import( "./pages/policemodule/systemlogs/ImportGuestLogs.vue" );
      }
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/logs/rtologs",
    name: "RTOLogs",
    component: () => {
      if (window.userType == 1) {
        return import("./pages/adminmodule/systemlogs/RTOLogs.vue");
      } else if ( window.userType == 3 ) {
        return import( "./pages/policemodule/systemlogs/RTOLogs.vue" );
      }
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/logs/panogs",
    name: "PANLogs",
    component: () => {
      if (window.userType == 1) {
        return import("./pages/adminmodule/systemlogs/PANLogs.vue");
      } else if ( window.userType == 3 ) {
        return import( "./pages/policemodule/systemlogs/PANLogs.vue" );
      }
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/logs/nepalipanogs",
    name: "NepaliPANLogs",
    component: () => {
      if (window.userType == 1) {
        return import("./pages/adminmodule/systemlogs/NepaliPANLogs.vue");
      } else if ( window.userType == 3 ) {
        return import( "./pages/policemodule/systemlogs/NepaliPANLogs.vue" );
      }
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/logs/nepalinagriktaogs",
    name: "NepaliNagriktaLogs",
    component: () => {
      if (window.userType == 1) {
        return import("./pages/adminmodule/systemlogs/NepaliNagriktaLogs.vue");
      } else if ( window.userType == 3 ) {
        return import( "./pages/policemodule/systemlogs/NepaliNagriktaLogs.vue" );
      }
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/logs/emiratesidlogs",
    name: "EmiratesIdLogs",
    component: () => {
      if (window.userType == 1) {
        return import("./pages/adminmodule/systemlogs/EmiratesIdLogs.vue");
      } else if ( window.userType == 3 ) {
        return import( "./pages/policemodule/systemlogs/EmiratesIdLogs.vue" );
      }
    },
    meta: {
      requiresAuth: true,
    },
  },


  {
    path: "/cloudtelephony/virtualnumbers",
    name: "VirtualNumbers",
    component: () => import( "./pages/adminmodule/cloudtelephony/VirtualNumbers.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cloudtelephony/custometunes",
    name: "CustomTunes",
    component: () => import( "./pages/adminmodule/cloudtelephony/CustomTunes.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/cloudtelephony/virtualnumbers/set/:param1/:param2',
    name: 'VirtualNumberSet',
    component: () => import( './pages/adminmodule/cloudtelephony/VirtualNumberSet.vue' ),
    meta: { requiresAuth: true, },
  },
  {
    path: '/cloudtelephony/virtualnumbers/existset/:param1/:param2',
    name: 'VirtualNumberExistSet',
    component: () => import( './pages/adminmodule/cloudtelephony/VirtualNumberExistSet.vue' ),
    meta: { requiresAuth: true, },
  },
  //    Setting Routes   //
  {
    path: "/settings/chargessetting",
    name: "ChargesSetting",
    component: () => import( "./pages/adminmodule/settings/chargeslist/ChargesSetting.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/settings/chargessetting/hotel",
    name: "HotelCharges",
    component: () => import( "./pages/adminmodule/settings/chargeslist/HotelCharges.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/settings/chargessetting/vehiclerental",
    name: "VehicleRentalCharges",
    component: () => import( "./pages/adminmodule/settings/chargeslist/VehicleRentalCharges.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/settings/chargessetting/labourcontractor",
    name: "LabourContractorCharges",
    component: () => import( "./pages/adminmodule/settings/chargeslist/LabourContractorCharges.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/settings/chargessetting/tenantverification",
    name: "TenantVerificationCharges",
    component: () => import( "./pages/adminmodule/settings/chargeslist/TenantVerificationCharges.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/settings/guestrecordsetting",
    name: "GuestRecordSetting",
    component: () => import( "./pages/adminmodule/settings/GuestRecordSetting.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/settings/hostsetting",
    name: "HostSetting",
    component: () => import( "./pages/adminmodule/settings/HostSetting.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/settings/invoice",
    name: "InvoiceSetting",
    component: () => import( "./pages/adminmodule/settings/Invoice.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  //// law users ///////
  // {
  //   path: "/lawuser/add",
  //   name: "NewUserLaw",
  //   component: () => import( "./pages/adminmodule/lawUser/addLawUser.vue" ),
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  {
    path: "/lawuser/listing",
    name: "UserListingLaw",
    component: () => import( "./pages/adminmodule/lawUser/listLawUser.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/lawuser/permissionsets",
    name: "UserpermissionsetsLaw",
    component: () => import( "./pages/adminmodule/lawUser/UsersRole.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  ///////////////////////////////
  //  Host Routes  //

  // {
  //   path: "/hosts/add",
  //   name: "AddHost",
  //   component: () => import("./pages/adminmodule/hosts/AddHost.vue"),
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  {
    path: "/hosts/active",
    name: "HostListing",
    component: () => {
      if ( window.userType == 1 ) {
        return import( "./pages/adminmodule/hosts/ListActiveHosts.vue" );
      } else if ( window.userType == 3 ) {
        return import( "./pages/policemodule/hosts/ListActiveHosts.vue" );
      }
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/hosts/incompleted",
    name: "HostUnApprovedListing",
    component: () => {
      if ( window.userType == 1 ) {
        return import( "./pages/adminmodule/hosts/ListHostUnapproved.vue" );
      } else if ( window.userType == 3 ) {
        return import( "./pages/policemodule/hosts/ListHostUnapproved.vue" );
      }
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/hosts/suspended",
    name: "HostSaspendedListing",
    component: () => {
      if ( window.userType == 1 ) {
        return import( "./pages/adminmodule/hosts/ListHostSuspended.vue" );
      } else if ( window.userType == 3 ) {
        return import( "./pages/policemodule/hosts/ListHostSuspended.vue" );
      }
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/employerscontractors/active",
    name: "ActiveEmployer",
    component: () => import( "./pages/policemodule/employerandcontractor/ActiveEmployer.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/employerscontractors/incomplete",
    name: "IncompleteEmployer",
    component: () => import( "./pages/policemodule/employerandcontractor/IncompleteEmployer.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/employerscontractors/suspended",
    name: "SuspendedEmployer",
    component: () => import( "./pages/policemodule/employerandcontractor/SuspendedEmployer.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/employerscontractors/userslist",
    name: "EmployerUserList",
    component: () => import( "./pages/policemodule/employerandcontractor/EmployerUserList.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/employerscontractors/details/:employerId?',
    name: 'EmployerInfo',
    component: () => import("./pages/policemodule/employerandcontractor/employerfulldetails/EmployerInfo.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  
  {
    path: "/employeeslabourers/active",
    name: "ListActiveEmployerLabour",
    component: () => import( "./pages/policemodule/employeelabour/ListActiveEmployeesLabour.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/employeeslabourers/incomplete",
    name: "ListIncompleteEmployerLabour",
    component: () => import( "./pages/policemodule/employeelabour/ListIncompleteEmployeesLabour.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/employeeslabourers/inactive",
    name: "ListInactiveEmployerLabour",
    component: () => import( "./pages/policemodule/employeelabour/ListInactiveEmployeesLabour.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  // verifiedtenants start
  {
    path: "/verifiedtenants/propertieslist",
    name: "PropertyList",
    component: () => import( "./pages/policemodule/verifiedtenants/PropertyList.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/verifiedtenants/propertyownerslist",
    name: "TenantPropertyOwnerList",
    component: () => import( "./pages/policemodule/verifiedtenants/PropertyOwnerList.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/verifiedtenants/representativeslist",
    name: "TenantOwnerRepresentative",
    component: () => import( "./pages/policemodule/verifiedtenants/OwnerRepresentative.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/verifiedtenants/realestateagentslist",
    name: "RealEstateAgents",
    component: () => import( "./pages/policemodule/verifiedtenants/RealEstateAgents.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
    // verifiedtenants end
  // tenants start
  {
    path: "/tenants/tenantslist",
    name: "TenantRentAgreementTenant",
    component: () => import( "./pages/policemodule/tenants/RentAgreementTenant.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/tenants/agreementlist",
    name: "TenantsRentAgreementsList",
    component: () => import( "./pages/policemodule/tenants/RentAgreementsList.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/tenants/expiringagreements",
    name: "TenantsRentAgreementsNearExpiry",
    component: () => import( "./pages/policemodule/tenants/RentAgreementsNearExpiry.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/tenants/expiredagreements",
    name: "TenantRentAgreementsExpired",
    component: () => import( "./pages/policemodule/tenants/RentAgreementsExpired.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  // tenants end
  {
    path: "/firlist",
    name: "FirList",
    component: () => import( "./pages/policemodule/FirList.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/pcc/request",
    name: "PCCRequest",
    component: () => import( "./pages/policemodule/PCCRequest.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/notifications",
    name: "Notifications",
    component: () => import( "./pages/policemodule/Notifications.vue" ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/chatbot',
    name: 'ChatBotLandingPage',
    exact: true,
    component: () =>
      import( './pages/chatbot.vue' ),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/webbot/:param1',
    name: 'WebBot',
    exact: true,
    component: () =>
      import('./pages/WebBot.vue'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/userregistrationform',
    name: 'UserRegistrationForm',
    exact: true,
    component: () =>
      import('./pages/UserRegistrationForm.vue'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/employeeverificationform',
    name: 'EmployeeRegistrationForm',
    exact: true,
    component: () =>
      import('./pages/EmployeeRegistrationForm.vue'),
    meta: {
      requiresAuth: false,
    },
  },
  ////////////

  {
    path: "/:pathMatch(.*)",
    name: "notfound",
    component: () => import( "./pages/NotFound" ),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/underprogress",
    name: "underprogress",
    exact: true,
    component: () => import( "./pages/UnderProgress.vue" ),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/demo",
    name: "DemoPage",
    component: () => import( "./pages/DemoPage.vue" ),
    meta: {
      requiresAuth: true,
    },
  },

];

const router = createRouter( {
  history: createWebHistory( process.env.BASE_URL ),
  routes,
} );
router.beforeEach( async ( to, from, next ) => {
  await store.dispatch( "checkLoginCredentials" );
  if ( checkEmpty( store.state.loggedInUserSessions.userfk ) && checkEmpty( store.state.loggedInUserSessions.user ) ) {
    window.userType = store.state.loggedInUserSessions.user.ad23;
  }
  const authenticatedUser = store.state.loggedInUserSessions.user_reference;
  const requiresAuth = to.matched.some( ( record ) => record.meta.requiresAuth );
  const checkModuleAccess = to.matched.some( ( record ) => record.meta.checkModuleAccess );
  const moduleId = to.meta.moduleId;
  const permission_fks = [ 1, 11, 12 ];

  if ( requiresAuth && !authenticatedUser ) {
    next( "/" );
  } else {
    if ( checkModuleAccess ) {
      if ( permission_fks.includes( moduleId ) ) {
        next();
      } else {
        next( false );
      }
    } else {
      next();
    }
  }
} );

export default router;