<template>
    <div class="row">
        <div class="col-lg-12 col-md-12 col-12">
            <div class="custom-form-group">
                <label class="intelligent-campagin-modal-header">RCS / iMessage Campaign</label>
            </div>
        </div>
    </div>
    <div class="campaign-tab-outer-scroll" :style="{ height: '377px' }">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-12">
                <div class="custom-form-group">
                    <label class="form-label">Template Name <span class="text-danger">*</span></label>
                    <input type="text" v-model="templatename" class="form-control" placeholder="Enter Template Name"
                        maxlength="250" autocomplete="off" />
                    <div class="custom-error" v-if="v$.templatename.$error">
                        {{ v$.templatename.$errors[0].$message }}
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-12">
                <div class="custom-form-group">
                    <label class="form-label">Select Header Image <span class="text-danger">*</span></label>
                    <FileUpload mode="basic" accept=".csv" ref="importvoterfile" class="custom-upload-btn-ultima"
                        v-model="whtheaderimage" v-on:change="handleimsgImageUploader()" />
                    <div class="custom-error" v-if="v$.whtheaderimage.$error">
                        {{ v$.whtheaderimage.$errors[0].$message }}
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-12">
                <div class="custom-form-group">
                    <label class="form-label">Select Header Video<span class="text-danger">*</span></label>
                    <FileUpload mode="basic" accept=".csv" ref="importvoterfile" class="custom-upload-btn-ultima"
                        v-model="whtheadervideo" v-on:change="handleimsgVideoUploader()" />
                    <div class="custom-error" v-if="v$.whtheadervideo.$error">
                        {{ v$.whtheadervideo.$errors[0].$message }}
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-md-12 col-12">
                <div class="custom-form-group">
                    <label for="formmessageInput" class="form-label">Message <span class="text-danger">*</span></label>
                    <textarea type="text" v-model="imessage" class="form-control" id="formmessageInput"
                        placeholder="Enter Message" autocomplete="off" rows="3"></textarea>
                    <div class="custom-error" v-if="v$.imessage.$error">
                        {{ v$.imessage.$errors[0].$message }}
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-4 col-12">
                <div class="custom-form-group">
                    <label class="form-label">First Button Name <span class="text-danger">*</span></label>
                    <input type="text" v-model="firstbtnname" class="form-control" placeholder="Enter First Button Name"
                        maxlength="30" autocomplete="off" />
                    <div class="custom-error" v-if="v$.firstbtnname.$error">
                        {{ v$.firstbtnname.$errors[0].$message }}
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-4 col-12">
                <div class="custom-form-group">
                    <label class="form-label">Second Button Name <span class="text-danger">*</span></label>
                    <input type="text" v-model="secondbtnname" class="form-control" placeholder="Enter Second Button Name"
                        maxlength="30" autocomplete="off" />
                    <div class="custom-error" v-if="v$.secondbtnname.$error">
                        {{ v$.secondbtnname.$errors[0].$message }}
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-4 col-12">
                <div class="custom-form-group">
                    <label class="form-label">Third Button Name</label>
                    <input type="text" v-model="thirdbtnname" class="form-control" placeholder="Enter Third Button Name"
                        maxlength="30" autocomplete="off" />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="custom-form-group text-center">
                    <button type="button" class="btn custom-form-group-btn" @click="imessagesubmitbtn()"
                        :disabled="imsgbtnloader" style="width: 150px;">
                        <span v-if="!imsgbtnloader">Submit</span>
                        <div class="spinner-border text-light custom-spinner-loader-btn" role="status" v-if="imsgbtnloader">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ApiService from "../../service/ApiService";
import useValidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
export default {
    data() {
        return {
            v$: useValidate(),
            imsgbtnloader: false,
            templatename: '',
            whtheaderimage: null,
            whtheadervideo: null,
            imessage: '',
            firstbtnname: '',
            secondbtnname: '',
            thirdbtnname: '',
        };
    },
    validations() {
        return {
            templatename: {
                required: helpers.withMessage("Please enter template name", required),
            },
            whtheaderimage: {
                required: helpers.withMessage("Please upload image", required),
            },
            whtheadervideo: {
                required: helpers.withMessage("Please upload video", required),
            },
            imessage: {
                required: helpers.withMessage("Please enter message", required),
            },
            firstbtnname: {
                required: helpers.withMessage("Please enter button name", required),
            },
            secondbtnname: {
                required: helpers.withMessage("Please enter button name", required),
            },

        };
    },
    ApiService: null,

    created() {
        this.ApiService = new ApiService();
    },
    mounted() {
    },
    methods: {
        imessagesubmitbtn() {
            this.v$.$validate();
            var formData = new FormData();
            formData.append("al2", this.templatename);
            formData.append("al3", this.imessage);
            formData.append("al5", this.firstbtnname);
            formData.append("al6", this.secondbtnname);
            formData.append("al7", this.thirdbtnname);
            if (this.whtheaderimage) {
                formData.append("al4", this.whtheaderimage);
            }
            if (this.whtheadervideo) {
                formData.append("al4", this.whtheadervideo);
            }
            if (!this.v$.$error) {
                this.$store.dispatch("addCampaignStatus");
            } else {
                this.$error;
            }

        },
        handleimsgImageUploader() {

        },
        handleimsgVideoUploader() {

        },
    },
};
</script>
<style scoped>
.campaign-modal-outer-border {
    padding: 15px;
    border: 1px solid #dee2e6;
    border-top: 0;
}
.intelligent-campagin-modal-header {
    font-family: 'OpenSans-Regular';
    font-size: 16px;
    letter-spacing: 0.52px;
    line-height: 18px;
    margin-top: 10px;
    color: #0C1755;
    font-weight: 600;
    text-align: center;
}

.campaign-tab-outer-scroll {
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
}

.campaign-tab-outer-scroll::-webkit-scrollbar {
    display: block;
}

.campaign-tab-outer-scroll::-webkit-scrollbar {
    width: 0.3em;
    height: 0.3em;
}

.campaign-tab-outer-scroll::-webkit-scrollbar-thumb {
    background-color: rgb(113 115 119 / 49%);
    border-radius: 3px;
}
</style>