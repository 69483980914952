<template>
    <div class="row">
        <div class="col-lg-12 col-md-12 col-12">
            <div class="custom-form-group">
                <label class="intelligent-campagin-modal-header">WhatsApp Campaign</label>
            </div>
        </div>
    </div>
    <div class="campaign-tab-outer-scroll" :style="{ height: '377px' }">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-12">
                <div class="custom-form-group">
                    <label class="form-label">Template Name <span class="text-danger">*</span></label>
                    <input type="text" v-model="templatename" class="form-control" placeholder="Enter Template Name" maxlength="512" autocomplete="off"
                        v-on:keyup="checkTemplateName()" />
                    <div class="custom-error" v-if="v$.templatename.$error"> {{ v$.templatename.$errors[0].$message }} </div>
                    <div class="custom-error" v-if="duplicateTemplateMsg"> {{ duplicateTemplateMsg }} </div>
                </div>
            </div>
            <div class="col-lg-12 col-md-12 col-12">
                <div class="custom-form-group">
                    <label class="form-label">Alias Name <span class="text-danger">*</span></label>
                    <input type="text" v-model="aliasname" class="form-control" placeholder="Enter Alias Name" maxlength="250" autocomplete="off" />
                    <div class="custom-error" v-if="v$.aliasname.$error"> {{ v$.aliasname.$errors[0].$message }} </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-12">
                <div class="custom-form-group">
                    <label class="form-label">Choose Category<span class="text-danger">*</span></label>
                    <MultiSelect v-model="categorytype" :options="categorytypelist" :searchable="false" label="label" placeholder="Select Category"
                        class="multiselect-custom text-capitalize" :canClear="false" :closeOnSelect="true" noOptionsText="No Result Found" :object="true" />
                    <div class="custom-error" v-if="v$.categorytype.$error"> {{ v$.categorytype.$errors[0].$message }} </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-12">
                <div class="custom-form-group">
                    <label class="form-label">Choose Language<span class="text-danger">*</span></label>
                    <MultiSelect v-model="languagevalue" :options="languagelist" :searchable="true" label="label" placeholder="Select Language"
                        class="multiselect-custom text-capitalize" :canClear="true" :closeOnSelect="true" noOptionsText="No Result Found" :object="true" />
                    <div class="custom-error" v-if="v$.languagevalue.$error"> {{ v$.languagevalue.$errors[0].$message }} </div>
                </div>
            </div>
        </div>
        <div class="row" v-if="languagevalue != ''">
            <div class="col-lg-8 col-md-8 col-12">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-12">
                        <div class="card-template">
                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-12">
                                    <div class="custom-form-group">
                                        <label class="form-label">Vendor Template ID<span class="text-danger">*</span></label>
                                        <input type="text" v-model="vendortemplateid" class="form-control" placeholder="Enter Vendor Template ID"
                                            @keypress="onlyNumber" autocomplete="off" />
                                        <div class="custom-error" v-if="v$.vendortemplateid.$error"> {{ v$.vendortemplateid.$errors[0].$message }} </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-12">
                                    <div class="custom-form-group">
                                        <label class="form-label">Variable Name</label>
                                        <input type="text" v-model="variablename" class="form-control" placeholder="Enter Variable Name" maxlength="20"
                                            autocomplete="off" />
                                        <!-- <div class="custom-error" v-if="v$.variablename.$error">
                                            {{ v$.variablename.$errors[0].$message }}
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-template mt-3">
                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-12">
                                    <div class="custom-form-group">
                                        <label class="form-label">Header<span class="text-danger">*</span></label>
                                        <div class="custom-group-radio-box-btn">
                                            <div class="form-check form-check-inline custom-radio-check-form">
                                                <input class="form-check-input custom-radio-btn" v-model="headertype" type="radio" name="none-option"
                                                    id="headernone" value="0" @click="clearheader()" />
                                                <label class="form-check-label" for="headernone">None</label>
                                            </div>
                                            <div class="form-check form-check-inline custom-radio-check-form">
                                                <input class="form-check-input custom-radio-btn" v-model="headertype" type="radio" name="text-option"
                                                    id="headermedia" value="1" @click="clearheader()" />
                                                <label class="form-check-label" for="headermedia">Media</label>
                                            </div>
                                        </div>
                                        <div class="custom-error" v-if="v$.headertype.$error"> {{ v$.headertype.$errors[0].$message }} </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-12" v-if="headertype == 1">
                                    <div class="custom-form-group">
                                        <label class="form-label">Select Media Type<span class="text-danger">*</span></label>
                                        <MultiSelect v-model="mediatype" @change="mediatypechanges()" :options="mediatypelist" :searchable="true" label="label"
                                            placeholder="Select Media" class="multiselect-custom text-capitalize" :canClear="true" :closeOnSelect="true"
                                            noOptionsText="No Result Found" :object="true" />
                                        <div class="custom-error" v-if="v$.mediatype.$error"> {{ v$.mediatype.$errors[0].$message }} </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-12" v-if="headertype == 1 && mediatype.value == 1">
                                    <div class="custom-form-group">
                                        <label class="form-label">Upload Image <span class="text-danger">*</span></label>
                                        <FileUpload mode="basic" accept="image/*" :auto="true" ref="whtheaderimage" class="custom-upload-btn-ultima"
                                            v-model="whtheaderimage" v-on:change="handleWhtImageUploader()" />
                                        <div class="custom-error" v-if="v$.whtheaderimage.$error"> {{ v$.whtheaderimage.$errors[0].$message }} </div>
                                        <div class="custom-error" v-if="errorwhtheaderimage">{{ errorwhtheaderimage }} </div>
                                        <div class="text-primary mt-1" v-if="exitwhatheaderpreviewimgurl != null">{{ exitwhatheaderpreviewimgurl.name }}</div>
                                    </div>
                                </div>
                                <!-- <div class="col-lg-4 col-md-4 col-12" v-if="exitwhatheaderpreviewimgurl != null">
                                            <img :src="exitwhatheaderpreviewimgurl.objectURL" height="72" class="
                                        rounded
                                        bg-light
                                        me-auto
                                        d-block
                                        " alt="" />
                                        </div> -->
                                <div class="col-lg-6 col-md-6 col-12" v-if="headertype == 1 && mediatype.value == 2">
                                    <div class="custom-form-group">
                                        <label class="form-label">Upload Video<span class="text-danger">*</span></label>
                                        <FileUpload mode="basic" :auto="true" ref="whtheadervideo" class="custom-upload-btn-ultima" v-model="whtheadervideo"
                                            v-on:change="handleWhtVideoUploader()" />
                                        <div class="custom-error" v-if="v$.whtheadervideo.$error"> {{ v$.whtheadervideo.$errors[0].$message }} </div>
                                        <div class="custom-error" v-if="errorwhtheadervideo">{{ errorwhtheadervideo }} </div>
                                        <div class="text-primary mt-1" v-if="exitwhatheaderpreviewvideourl != null">{{ exitwhatheaderpreviewvideourl.name }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-12" v-if="headertype == 1 && mediatype.value == 3">
                                    <div class="custom-form-group">
                                        <label class="form-label">Upload Document<span class="text-danger">*</span></label>
                                        <FileUpload mode="basic" :auto="true" ref="whtheaderdoc" class="custom-upload-btn-ultima" v-model="whtheaderdoc"
                                            v-on:change="handleWhtDocUploader()" />
                                        <div class="custom-error" v-if="v$.whtheaderdoc.$error"> {{ v$.whtheaderdoc.$errors[0].$message }} </div>
                                        <div class="custom-error" v-if="errorwhtheaderdoc">{{ errorwhtheaderdoc }}</div>
                                        <div class="text-primary mt-1" v-if="exitwhatheaderpreviewdovurl != null">{{ exitwhatheaderpreviewdovurl.name }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-template mt-3">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div class="custom-form-group">
                                        <label for="formmessageInput" class="form-label">Message <span class="text-danger">*</span></label>
                                        <textarea type="text" id="formmessageInput" v-model="whatsappmessage" class="form-control custom-text-area-scroll"
                                            placeholder="Enter Message" v-on:keyup="htmlconvertmsg()" autocomplete="off" rows="3" maxlength="1024"
                                            :style="{ resize: 'vertical' }"></textarea>
                                        <!-- <div class="text-muted text-end" v-if="whatsappmessage.length != 0">{{
                                            templatebodyRemainCountTotal
                                        }}/512</div> -->
                                        <div class="custom-error" v-if="v$.whatsappmessage.$error"> {{ v$.whatsappmessage.$errors[0].$message }} </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-template mt-3">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div class="custom-form-group">
                                        <label class="form-label">Footer</label>
                                        <input type="text" v-model="footername" class="form-control" placeholder="Enter Footer" maxlength="60"
                                            autocomplete="off" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-template mt-3">
                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-12">
                                    <div class="custom-form-group">
                                        <label class="form-label">Buttons</label>
                                        <MultiSelect v-model="buttontype" @change="buttontypechanges()" :options="buttontypelist" :searchable="false"
                                            label="label" placeholder="Select Button" class="multiselect-custom text-capitalize" :canClear="false"
                                            :closeOnSelect="true" noOptionsText="No Result Found" :object="true" />
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-12" v-if="buttontype.value == 1">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-4 col-12">
                                            <div class="row">
                                                <div class="col-lg-12 col-md-12 col-12">
                                                    <div class="custom-form-group">
                                                        <label class="form-label">First Button Name<span class="text-danger">*</span></label>
                                                        <input type="text" v-model="firstbtnname" class="form-control" placeholder="Enter Button Name"
                                                            maxlength="30" autocomplete="off" />
                                                        <div class="custom-error" v-if="v$.firstbtnname.$error"> {{ v$.firstbtnname.$errors[0].$message }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-12 col-md-12 col-12" v-if="firstbtnname != ''">
                                                    <div class="custom-form-group">
                                                        <label class="form-label">Select Action Type<span class="text-danger">*</span></label>
                                                        <MultiSelect v-model="firstbtnactiontype" :options="actionTypeList" :searchable="true" label="label"
                                                            placeholder="Select..." class="multiselect-custom text-capitalize" :canClear="false"
                                                            :closeOnSelect="true" noOptionsText="No Result Found"
                                                            @select="checkActionTypeResponseBtnFirst(firstbtnactiontype)" :object="false" />
                                                        <div class="custom-error" v-if="v$.firstbtnactiontype.$error"> {{
        v$.firstbtnactiontype.$errors[0].$message }} </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-12 col-md-12 col-12" v-if="firstbtnactiontype != ''">
                                                    <div class="custom-form-group">
                                                        <label class="form-label">Select Response<span class="text-danger">*</span></label>
                                                        <MultiSelect v-model="firstbtnresponse" :options="responseListFirst" :searchable="true" label="label"
                                                            placeholder="Select..." class="multiselect-custom text-capitalize" :canClear="false"
                                                            :closeOnSelect="true" noOptionsText="No Result Found" :object="false" />
                                                        <div class="custom-error" v-if="v$.firstbtnresponse.$error"> {{ v$.firstbtnresponse.$errors[0].$message
                                                            }} </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-12">
                                            <div class="row">
                                                <div class="col-lg-12 col-md-12 col-12">
                                                    <div class="custom-form-group">
                                                        <label class="form-label">Second Button Name</label>
                                                        <input type="text" v-model="secondbtnname" class="form-control" placeholder="Enter Button Name"
                                                            maxlength="30" autocomplete="off" />
                                                    </div>
                                                </div>
                                                <div class="col-lg-12 col-md-12 col-12" v-if="secondbtnname != ''">
                                                    <div class="custom-form-group">
                                                        <label class="form-label">Select Action Type<span class="text-danger">*</span></label>
                                                        <MultiSelect v-model="secondbtnactiontype" :options="actionTypeList" :searchable="true" label="label"
                                                            placeholder="Select..." class="multiselect-custom text-capitalize" :canClear="false"
                                                            :closeOnSelect="true" noOptionsText="No Result Found"
                                                            @select="checkActionTypeResponseBtnSecond(secondbtnactiontype)" :object="false" />
                                                        <div class="custom-error" v-if="v$.secondbtnactiontype.$error"> {{
        v$.secondbtnactiontype.$errors[0].$message }} </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-12 col-md-12 col-12" v-if="secondbtnactiontype != ''">
                                                    <div class="custom-form-group">
                                                        <label class="form-label">Select Response<span class="text-danger">*</span></label>
                                                        <MultiSelect v-model="secondbtnresponse" :options="responseListSecond" :searchable="true" label="label"
                                                            placeholder="Select..." class="multiselect-custom text-capitalize" :canClear="false"
                                                            :closeOnSelect="true" noOptionsText="No Result Found" :object="false" />
                                                        <div class="custom-error" v-if="v$.secondbtnresponse.$error"> {{
        v$.secondbtnresponse.$errors[0].$message }} </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-4 col-12">
                                            <div class="row">
                                                <div class="col-lg-12 col-md-12 col-12">
                                                    <div class="custom-form-group">
                                                        <label class="form-label">Third Button Name</label>
                                                        <input type="text" v-model="thirdbtnname" class="form-control" placeholder="Enter Button Name"
                                                            maxlength="30" autocomplete="off" />
                                                    </div>
                                                </div>
                                                <div class="col-lg-12 col-md-12 col-12" v-if="thirdbtnname != ''">
                                                    <div class="custom-form-group">
                                                        <label class="form-label">Select Action Type<span class="text-danger">*</span></label>
                                                        <MultiSelect v-model="thirdbtnactiontype" :options="actionTypeList" :searchable="true" label="label"
                                                            placeholder="Select..." class="multiselect-custom text-capitalize" :canClear="false"
                                                            :closeOnSelect="true" noOptionsText="No Result Found"
                                                            @select="checkActionTypeResponseBtnThird(thirdbtnactiontype)" :object="false" />
                                                        <div class="custom-error" v-if="v$.thirdbtnactiontype.$error"> {{
        v$.thirdbtnactiontype.$errors[0].$message }} </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-12 col-md-12 col-12" v-if="thirdbtnactiontype != ''">
                                                    <div class="custom-form-group">
                                                        <label class="form-label">Select Response<span class="text-danger">*</span></label>
                                                        <MultiSelect v-model="thirdbtnresponse" :options="responseListThird" :searchable="true" label="label"
                                                            placeholder="Select..." class="multiselect-custom text-capitalize" :canClear="false"
                                                            :closeOnSelect="true" noOptionsText="No Result Found" :object="false" />
                                                        <div class="custom-error" v-if="v$.thirdbtnresponse.$error"> {{ v$.thirdbtnresponse.$errors[0].$message
                                                            }} </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-12" v-if="buttontype.value == 2">
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6 col-12">
                                            <div class="custom-form-group">
                                                <label class="form-label">Call Button Text</label>
                                                <input type="text" v-model="callbtntext" class="form-control" placeholder="Enter Button Text" maxlength="30"
                                                    autocomplete="off" />
                                                <div class="custom-error" v-if="v$.callbtntext.$error"> {{ v$.callbtntext.$errors[0].$message }} </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-12">
                                            <div class="custom-form-group">
                                                <label class="form-label">Mobile No.</label>
                                                <div class="input-group custom-input-group">
                                                    <div class="col-lg-4 col-md-4 col-12">
                                                        <MultiSelect v-model="countrycode" :options="countryCodeList" :searchable="true" label="label"
                                                            placeholder="Select" disabled class="multiselect-custom custom-title-multiselect" :canClear="false"
                                                            :closeOnSelect="true" :object="true" noOptionsText="No Result" :canDeselect="false" />
                                                    </div>
                                                    <div class="col-lg-8 col-md-8 col-12">
                                                        <input type="text" v-model="callmobileno" class="form-control" placeholder="Enter Mobile No."
                                                            maxlength="10" id="formMobileNoInput" @keypress="onlyNumberMobile" autocomplete="off" />
                                                    </div>
                                                    <div class="custom-error" v-if="v$.callmobileno.$error"> {{ v$.callmobileno.$errors[0].$message }} </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <divider class="mb-3" v-if="buttontype.value == 2" />
                                <div class="col-lg-12 col-md-12 col-12" v-if="buttontype.value == 2">
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6 col-12">
                                            <div class="custom-form-group">
                                                <label class="form-label">URL Button Text</label>
                                                <input type="text" v-model="urlbtntext" class="form-control" placeholder="Enter Button Text" maxlength="30"
                                                    autocomplete="off" />
                                                <div class="custom-error" v-if="v$.urlbtntext.$error"> {{ v$.urlbtntext.$errors[0].$message }} </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-md-12 col-12">
                                            <div class="custom-form-group">
                                                <label class="form-label">Website URL</label>
                                                <input type="text" v-model="websiteurllink" class="form-control" placeholder="Enter Website URL" maxlength="70"
                                                    autocomplete="off" />
                                                <!-- <div class="custom-error"
                                                    v-if="websiteurllinkErr || websiteurllink != ''">
                                                    {{ websiteurllinkErr }}
                                                </div> -->
                                                <div class="custom-error" v-if="v$.websiteurllink.$error"> {{ v$.websiteurllink.$errors[0].$message }} </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-12">
                                <div class="custom-form-group text-center">
                                    <button type="button" class="btn custom-form-group-btn" @click="whatsappsubmitbtn()" :disabled="whtbtnloader"
                                        style="width: 150px;">
                                        <span v-if="!whtbtnloader">Submit</span>
                                        <div class="spinner-border text-light custom-spinner-loader-btn" role="status" v-if="whtbtnloader">
                                            <span class="visually-hidden">Loading...</span>
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-4 col-12">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-12">
                        <div class="intelligent-campagin-modal-header mb-2 text-center">Message Preview</div>
                    </div>
                </div>
                <div class="preview-card-template">
                    <div v-if="headertype == 1 && mediatype.value != ''">
                        <img v-if="whtheaderimage != null" :src="exitwhatheaderpreviewimgurl.objectURL" :style="{ width: '100%' }" alt="" />
                        <img v-if="mediatype.value == 1 && whtheaderimage == null" src="/assets/images/preview-img.jpg" alt="" :style="{ width: '100%' }" />
                        <video id="video-preview" controls :style="{ width: '210px' }" v-show="this.whtheadervideo != null" />
                        <img v-if="mediatype.value == 2 && this.whtheadervideo == null" src="/assets/images/preview-clip.jpg" alt=""
                            :style="{ width: '100%' }" />
                        <img v-if="mediatype.value == 3" src="/assets/images/preview-doc.jpg" alt="" :style="{ width: '100%' }" />
                    </div>
                    <div class="preview-body-msg" v-if="whatsappmessage != ''">
                        <p v-html="this.whatsappmessageinput"></p>
                    </div>
                    <div class="preview-footer-msg" v-if="footername"> {{ this.footername }} </div>
                    <Divider v-if="firstbtnname || secondbtnname || thirdbtnname" type="dashed" />
                    <button v-if="firstbtnname" type="button" class="btn custom-form-group-btn w-100 my-1 text-capitalize"><i class="pi pi-reply me-2"
                            style="font-size: 0.7rem;"></i>{{ this.firstbtnname }}</button>
                    <button v-if="secondbtnname" type="button" class="btn custom-form-group-btn w-100 my-1 text-capitalize"><i class="pi pi-reply me-2"
                            style="font-size: 0.7rem;"></i>{{ this.secondbtnname }}</button>
                    <button v-if="thirdbtnname" type="button" class="btn custom-form-group-btn w-100 my-1 text-capitalize"><i class="pi pi-reply me-2"
                            style="font-size: 0.7rem;"></i>{{ this.thirdbtnname }}</button>
                    <button v-if="callbtntext" type="button" class="btn custom-form-group-btn w-100 my-1 text-capitalize"><i class="pi pi-phone me-2"
                            style="font-size: 0.7rem;"></i>{{ this.callbtntext }}</button>
                    <button v-if="urlbtntext" type="button" class="btn custom-form-group-btn w-100 my-1 text-capitalize"><i class="pi pi-external-link me-2"
                            style="font-size: 0.7rem;"></i>{{ this.urlbtntext }}</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ApiService from "../../service/ApiService";
import useValidate from "@vuelidate/core";
import { required, requiredIf, minLength, helpers } from "@vuelidate/validators";
import { useToast } from 'vue-toast-notification';
export default {
    data () {
        const toast = useToast();
        return {
            v$: useValidate(),
            toast: toast,
            whtbtnloader: false,
            templatename: '',
            aliasname: '',
            categorytype: '',
            languagevalue: '',
            vendortemplateid: '',
            variablename: '',
            headertype: '',
            mediatype: '',
            whtheaderimage: null,
            errorwhtheaderimage: '',
            exitwhatheaderpreviewimgurl: null,

            whtheadervideo: null,
            errorwhtheadervideo: '',
            exitwhatheaderpreviewvideourl: null,

            whtheaderdoc: null,
            errorwhtheaderdoc: '',
            exitwhatheaderpreviewdovurl: null,

            whatsappmessage: '',
            footername: '',
            buttontype: '',
            firstbtnname: '',
            firstbtnresponse: '',
            secondbtnname: '',
            secondbtnresponse: '',
            thirdbtnname: '',
            thirdbtnresponse: '',
            responseListFirst: [],
            responseListSecond: [],
            responseListThird: [],
            responselist: [],
            countrycode: { value: 101, label: "+91" },
            callbtntext: '',
            callmobileno: '',
            urlbtntext: '',
            websiteurllink: '',
            categorytypelist: [
                // { label: "Authentication", value: 1, },
                { label: "Marketing", value: 2 },
                { label: "Utility", value: 3 },
            ],
            languagelist: [],
            mediatypelist: [
                { label: "Image", value: 1 },
                { label: "Video", value: 2 },
                { label: "Document", value: 3 },
            ],
            templatenameMxLimitCount: 250,
            templatenameRemainCountTotal: 250,
            templatebodyMxLimitCount: 250,
            templatebodyRemainCountTotal: 250,
            buttontypelist: [
                { label: "Select", value: '' },
                { label: "Quick Reply", value: 1 },
                { label: "Call To Action", value: 2 },
            ],
            countryCodeList: [],
            errStatus: false,
            websiteurllinkErr: '',
            errmsg: '',
            whatsappmessageinput: '',
            duplicateTemplateMsg: '',
            firstbtnactiontype: '',
            secondbtnactiontype: '',
            thirdbtnactiontype: '',
            actionTypeList: [
                { label: "Select", value: '' },
                { label: "Call Next Template", value: 1 },
                { label: "Activate Chatbot", value: 2 },
            ],
        };
    },
    validations () {
        return {
            templatename: {
                required: helpers.withMessage( "Please enter template name", required ),
            },
            aliasname: {
                required: helpers.withMessage( "Please enter alias name", required ),
            },
            categorytype: {
                required: helpers.withMessage( "Please select category type", required ),
            },
            languagevalue: {
                required: helpers.withMessage( "Please select language", required ),
            },
            vendortemplateid: {
                required: helpers.withMessage( "Please enter vendor template id", required ),
                minLength: helpers.withMessage(
                    "Template id should be min 10 length",
                    minLength( 10 )
                ),
            },
            // variablename: {
            //     required: helpers.withMessage("Please enter variable name", required),
            // },
            headertype: {
                required: helpers.withMessage( "Please select header", required ),
            },
            mediatype: {
                requiredIf: helpers.withMessage(
                    "Please select media type",
                    requiredIf(
                        this.headertype == 1
                    )
                ),
            },
            whtheaderimage: {
                requiredIf: helpers.withMessage(
                    "Please upload image",
                    requiredIf(
                        this.headertype == 1 && this.mediatype.value == 1
                    )
                ),
            },
            whtheadervideo: {
                requiredIf: helpers.withMessage(
                    "Please upload video",
                    requiredIf(
                        this.headertype == 1 && this.mediatype.value == 2
                    )
                ),
            },
            whtheaderdoc: {
                requiredIf: helpers.withMessage(
                    "Please upload document",
                    requiredIf(
                        this.headertype == 1 && this.mediatype.value == 3
                    )
                ),
            },
            whatsappmessage: {
                required: helpers.withMessage( "Please enter message", required ),
            },
            firstbtnname: {
                requiredIf: helpers.withMessage(
                    "Please enter button name",
                    requiredIf(
                        this.buttontype.value == 1
                    )
                ),
            },
            firstbtnactiontype: {
                requiredIf: helpers.withMessage(
                    "Please select action type",
                    requiredIf(
                        this.firstbtnname != '' && this.buttontype.value == 1
                    )
                ),
            },
            firstbtnresponse: {
                requiredIf: helpers.withMessage(
                    "Please select response",
                    requiredIf(
                        this.firstbtnactiontype != '' && this.buttontype.value == 1
                    )
                ),
            },
            secondbtnactiontype: {
                requiredIf: helpers.withMessage(
                    "Please select action type",
                    requiredIf(
                        this.secondbtnname != '' && this.buttontype.value == 1
                    )
                ),
            },
            secondbtnresponse: {
                requiredIf: helpers.withMessage(
                    "Please select response",
                    requiredIf(
                        this.secondbtnactiontype != '' && this.buttontype.value == 1
                    )
                ),
            },
            thirdbtnactiontype: {
                requiredIf: helpers.withMessage(
                    "Please select action type",
                    requiredIf(
                        this.thirdbtnname != '' && this.buttontype.value == 1
                    )
                ),
            },
            thirdbtnresponse: {
                requiredIf: helpers.withMessage(
                    "Please select response",
                    requiredIf(
                        this.thirdbtnactiontype != '' && this.buttontype.value == 1
                    )
                ),
            },
            callbtntext: {
                requiredIf: helpers.withMessage(
                    "Please enter button text",
                    requiredIf(
                        this.callmobileno != ''
                    )
                ),
            },
            callmobileno: {
                requiredIf: helpers.withMessage(
                    "Please enter Mobile No.",
                    requiredIf(
                        this.callbtntext != ''
                    )
                ), minLength: helpers.withMessage( 'Enter vaild Mobile No.', minLength( 10 ) )
            },
            urlbtntext: {
                requiredIf: helpers.withMessage(
                    "Please enter button text",
                    requiredIf(
                        this.websiteurllink != ''
                    )
                ),
            },
            websiteurllink: {
                requiredIf: helpers.withMessage(
                    "Please enter website URL",
                    requiredIf(
                        this.urlbtntext != ''
                    )
                ),
            },

        };
    },
    watch: {
        // websiteurllink(websiteurllink) {
        //     this.errmsg = "";
        //     this.validateUrl(websiteurllink);
        //     if (websiteurllink.length === 0) {
        //         this.websiteurllinkErr = "";
        //         this.errStatus = false;
        //     }
        // },
    },
    ApiService: null,

    created () {
        this.ApiService = new ApiService();
    },
    mounted () {
        this.getlanguages();
    },
    methods: {
        getlanguages ( e ) {
            this.ApiService.getlanguages( e ).then( ( data ) => {
                if ( data.status == 200 ) {
                    this.languagelist = data.data;
                } else {
                    this.languagelist = [];
                }
            } );
        },
        checkActionTypeResponseBtnFirst ( e ) {
            this.ApiService.getwhatsappResponseTemplateList( { action_type: e } ).then( ( data ) => {
                if ( data.status == 200 ) {
                    this.responseListFirst = data.data;
                } else {
                    this.responseListFirst = [];
                }
            } );
        },
        checkActionTypeResponseBtnSecond ( e ) {
            this.ApiService.getwhatsappResponseTemplateList( { action_type: e } ).then( ( data ) => {
                if ( data.status == 200 ) {
                    this.responseListSecond = data.data;
                } else {
                    this.responseListSecond = [];
                }
            } );
        },
        checkActionTypeResponseBtnThird ( e ) {
            this.ApiService.getwhatsappResponseTemplateList( { action_type: e } ).then( ( data ) => {
                if ( data.status == 200 ) {
                    this.responseListThird = data.data;
                } else {
                    this.responseListThird = [];
                }
            } );
        },
        showTemplateCountDown: function () {
            this.templatenameRemainCountTotal = this.templatenameMxLimitCount - this.templatename.length;
        },
        showTemplateBodyCountDown: function () {
            this.templatebodyRemainCountTotal = this.templatebodyMxLimitCount - this.whatsappmessage.length;
        },
        whatsappsubmitbtn () {
            this.v$.$validate();
            var formData = new FormData();
            formData.append( "cg28", 1 );
            formData.append( "cg3", this.templatename );
            formData.append( "cg2", this.aliasname );
            if ( this.categorytype ) {
                formData.append( 'cg21', this.categorytype.value );
                // formData.append('ak11', this.categorytype.label);
            }
            if ( this.languagevalue ) {
                formData.append( 'cg12', this.languagevalue.value );
                formData.append( 'cg13', this.languagevalue.label );
            }
            formData.append( "cg15", this.headertype );
            formData.append( "cg4", this.vendortemplateid );
            formData.append( "cg14", this.variablename );
            if ( this.headertype == 1 ) {
                formData.append( 'cg16', this.mediatype.value );
                if ( this.mediatype.value == 1 ) {
                    if ( this.whtheaderimage ) {
                        formData.append( "cg17", this.whtheaderimage );
                    }
                } else if ( this.mediatype.value == 2 ) {
                    if ( this.whtheadervideo ) {
                        formData.append( "cg17", this.whtheadervideo );
                    }
                } else if ( this.mediatype.value == 3 ) {
                    if ( this.whtheaderdoc ) {
                        formData.append( "cg17", this.whtheaderdoc );
                    }
                }
            }
            formData.append( "cg5", this.whatsappmessage );
            if ( this.footername ) {
                formData.append( "cg23", this.footername );
            }
            if ( this.firstbtnname != '' || this.callbtntext != '' || this.urlbtntext != '' ) {
                formData.append( 'cg20', this.buttontype.value );
            }
            if ( this.buttontype.value == 1 ) {
                if ( this.firstbtnname ) {
                    formData.append( "cg6", this.firstbtnname );
                }
                if ( this.secondbtnname ) {
                    formData.append( "cg8", this.secondbtnname );
                }
                if ( this.thirdbtnname ) {
                    formData.append( "cg10", this.thirdbtnname );
                }
                if ( this.firstbtnname != '' ) {
                    if ( this.firstbtnactiontype != '' ) {
                        formData.append( "cg29", this.firstbtnactiontype );
                    }
                }
                if ( this.firstbtnactiontype != '' ) {
                    if ( this.firstbtnresponse != '' ) {
                        formData.append( "cg7", this.firstbtnresponse );
                    }
                }
                if ( this.secondbtnname != '' ) {
                    if ( this.secondbtnactiontype != '' ) {
                        formData.append( "cg30", this.secondbtnactiontype );
                    }
                }
                if ( this.secondbtnactiontype != '' ) {
                    if ( this.secondbtnresponse != '' ) {
                        formData.append( "cg9", this.secondbtnresponse );
                    }
                }
                if ( this.secondbtnname != '' ) {
                    if ( this.thirdbtnactiontype != '' ) {
                        formData.append( "cg31", this.thirdbtnactiontype );
                    }
                }
                if ( this.thirdbtnactiontype != '' ) {
                    if ( this.thirdbtnresponse != '' ) {
                        formData.append( "cg11", this.thirdbtnresponse );
                    }
                }
            }
            if ( this.buttontype.value == 2 ) {
                if ( this.callbtntext ) {
                    formData.append( "cg24", this.callbtntext );
                }
                if ( this.callmobileno ) {
                    formData.append( "cg25", this.callmobileno );
                }
                if ( this.urlbtntext ) {
                    formData.append( "cg26", this.urlbtntext );
                }
                if ( this.websiteurllink ) {
                    formData.append( "cg27", this.websiteurllink );
                }
            }
            if ( !this.v$.$error && !this.errorwhtheaderimage && !this.errorwhtheadervideo && !this.errorwhtheaderdoc && !this.duplicateTemplateMsg ) {
                this.whtbtnloader = true;
                this.ApiService.addWhatsappCampaignTemplate( formData ).then( ( data ) => {
                    if ( data.status == 200 ) {
                        this.whtbtnloader = false;
                        this.$store.dispatch( "addCampaignStatus" );
                        var successMsg = data.message;
                        this.toast.open( {
                            message: successMsg,
                            type: "success",
                            duration: 3000,
                            position: "top-right",
                        } );
                        setTimeout( () => {
                            this.v$.$reset();
                        }, 0 );
                        this.redirectcampaigntemplate();
                    } else {
                        this.whtbtnloader = false;
                        var errorMsg = data.message;
                        this.toast.open( {
                            message: errorMsg,
                            type: "error",
                            duration: 3000,
                            position: "top-right",
                        } );
                    }
                } ).catch( ( error ) => {
                    console.log( error )
                } )

            } else {
                this.$error;
            }
        },
        handleWhtImageUploader () {
            var file = this.$refs.whtheaderimage.files[ 0 ];
            let allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
            if ( !file ) {
                this.errorwhtheaderimage = 'Please upload image';
            } else if ( !file.size > 2048 ) {
                this.errorwhtheaderimage = 'File size cannot exceed more than 2MB';
            } else if ( !file.name.match( allowedExtensions ) ) {
                this.errorwhtheaderimage = 'Invalid file type';
            } else {
                this.errorwhtheaderimage = '';
                this.whtheaderimage = this.$refs.whtheaderimage.files[ 0 ];
                this.exitwhatheaderpreviewimgurl = this.whtheaderimage;
            }
        },
        handleWhtVideoUploader () {
            var file = this.$refs.whtheadervideo.files[ 0 ];
            let allowedExtensions = /(\.mp4|\.mpeg)$/i;
            if ( !file ) {
                this.errorwhtheadervideo = 'Please upload video';
            } else if ( !file.size > 5120 ) {
                this.errorwhtheadervideo = 'File size cannot exceed more than 5MB';
            } else if ( !file.name.match( allowedExtensions ) ) {
                this.errorwhtheadervideo = 'Invalid file type';
            } else {
                this.errorwhtheadervideo = '';
                this.whtheadervideo = this.$refs.whtheadervideo.files[ 0 ];
                this.exitwhatheaderpreviewvideourl = this.whtheadervideo;
                this.previewVideo();
            }
        },
        previewVideo () {
            let video = document.getElementById( 'video-preview' );
            let reader = new FileReader();
            reader.readAsDataURL( this.whtheadervideo );
            reader.addEventListener( 'load', function () {
                video.src = reader.result;
            } );
        },
        handleWhtDocUploader () {
            var file = this.$refs.whtheaderdoc.files[ 0 ];
            let allowedExtensions = /(\.doc|\.docx|\.pdf|\.xml)$/i;
            if ( !file ) {
                this.errorwhtheaderdoc = 'Please upload file';
            } else if ( !file.size > 2048 ) {
                this.errorwhtheaderdoc = 'File size cannot exceed more than 2MB';
            } else if ( !file.name.match( allowedExtensions ) ) {
                this.errorwhtheaderdoc = 'Invalid file type';
            } else {
                this.errorwhtheaderdoc = '';
                this.whtheaderdoc = this.$refs.whtheaderdoc.files[ 0 ];
                this.exitwhatheaderpreviewdovurl = this.whtheaderdoc;
            }
        },
        clearheader () {
            this.mediatype = '';
            this.whtheaderimage = null;
            this.errorwhtheaderimage = '';
            this.exitwhatheaderpreviewimgurl = null;
            this.whtheadervideo = null;
            this.errorwhtheadervideo = '';
            this.exitwhatheaderpreviewvideourl = null;
            this.whtheaderdoc = null;
            this.errorwhtheaderdoc = '';
            this.exitwhatheaderpreviewdovurl = null;
        },
        mediatypechanges () {
            this.mediatype = '';
            this.whtheaderimage = null;
            this.errorwhtheaderimage = '';
            this.exitwhatheaderpreviewimgurl = null;
            this.whtheadervideo = null;
            this.errorwhtheadervideo = '';
            this.exitwhatheaderpreviewvideourl = null;
            this.whtheaderdoc = null;
            this.errorwhtheaderdoc = '';
            this.exitwhatheaderpreviewdovurl = null;
        },
        redirectcampaigntemplate () {
            window.location.href = "/campaigntemplates/whatsapp";
            // this.$router.push("/campaigntemplates/whatsapp");
        },
        onlyNumberMobile ( $event ) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if ( keyCode < 48 || keyCode > 57 ) {
                $event.preventDefault();
            }

            var mobileInput = document.getElementById( "formMobileNoInput" );
            if ( ( mobileInput.value == '' || mobileInput.length > 1 ) && ( keyCode == 48 || keyCode == 49 || keyCode == 50 || keyCode == 51 || keyCode == 52 ) ) {
                $event.preventDefault();
            }
        },
        buttontypechanges () {
            this.buttontype = '';
            this.firstbtnname = '';
            this.firstbtnactiontype = '';
            this.firstbtnresponse = '';
            this.secondbtnname = '';
            this.secondbtnresponse = '';
            this.secondbtnactiontype = '';
            this.thirdbtnname = '';
            this.thirdbtnactiontype = '';
            this.thirdbtnresponse = '';
            this.callbtntext = '';
            this.callmobileno = '';
            this.urlbtntext = '';
            this.websiteurllink = '';
        },
        // validateUrl(url) {
        //     this.errStatus = false;
        //     if (!this.validUrl(url)) {
        //         this.websiteurllinkErr = "Please enter a valid URL";
        //         this.errStatus = true;
        //     }
        //     if (this.errStatus === false) {
        //         this.websiteurllinkErr = "";
        //         return true;
        //     }
        // },

        validUrl: function ( url ) {
            var re = /^(?!((ftp|http(s?)):\/\/))?[^ "]+(\.[a-zA-Z]{2,})+$/;
            return re.test( url );
        },
        htmlconvertmsg () {
            var messagevalue = this.whatsappmessage;
            this.whatsappmessageinput = messagevalue.replace( /\n/g, '<br/>' );
        },

        checkTemplateName () {
            let wtpTemplates = this.$store.state.campaign.wtpTemplates
            if ( this.templatename.length > 3 ) {
                for ( var i = 0, len = wtpTemplates.length; i < len; i++ ) {
                    if ( wtpTemplates[ i ][ 'cg3' ].toLowerCase().trim() === this.templatename.toLowerCase() ) {
                        this.duplicateTemplateMsg = 'Template name already exits.';
                        break;
                    } else {
                        this.duplicateTemplateMsg = '';
                    }
                }
            }
        },
        onlyNumber ( $event ) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if ( keyCode < 48 || keyCode > 57 ) {
                $event.preventDefault();
            }
        },
    },
};
</script>
<style scoped>
.campaign-modal-outer-border {
    padding: 15px;
    border: 1px solid #dee2e6;
    border-top: 0;
}

.intelligent-campagin-modal-header {
    font-family: 'OpenSans-Regular';
    font-size: 16px;
    letter-spacing: 0.52px;
    line-height: 18px;
    margin-top: 10px;
    color: #0C1755;
    font-weight: 600;
    text-align: center;
}

.campaign-tab-outer-scroll {
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
}

.campaign-tab-outer-scroll::-webkit-scrollbar {
    display: block;
}

.campaign-tab-outer-scroll::-webkit-scrollbar {
    width: 0.3em;
    height: 0.3em;
}

.campaign-tab-outer-scroll::-webkit-scrollbar-thumb {
    background-color: rgb(113 115 119 / 49%);
    border-radius: 3px;
}

.card-template {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #c1d4f3;
    border-radius: 4px;
    padding: 14px 15px 0px 15px;
}

.preview-card-template {
    border: 1px solid #c1d4f3;
    border-radius: 6px;
    padding: 14px 15px 14px 15px;
    height: auto;
    min-height: 197px;
    background: #E1EEFF;
}

.preview-body-msg {
    font-size: 12px;
    letter-spacing: 0.28px;
    line-height: 15px;
    margin-top: 15px;
    font-family: 'OpenSans-Regular';
    color: #272727;
}

.preview-footer-msg {
    margin-top: 15px;
}
</style>