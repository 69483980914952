import { createStore } from "vuex";
import ApiService from "../../src/service/ApiService";
const apiService = new ApiService;
import commonFunction from "../composables/commonFunction"
const { decrypt, checkEmpty } = commonFunction()
export default createStore( {

    state: {
        loggedInUserSessions: {
            localstoragepath: null,
            user: null,
            client_info: null,
            user_reference: null,
            permission_fks: null,
            clientfk: null,
            userfk: null
        },

        AuthenticatedUserHeader: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
            "content-type": "application/json",
            Accept: "application/json",
        },

        billingStateParam: {
            showpaymenttwoloader: false,
            paymentStatus: null
        },
        billingStateParamRazorpay: {
            paymentStatus: null
        },
        newtempaltemodalstatus: false,
        maplink: null,
        latitude: null,
        longitude: null,
        addAdminUserList: null,
        campaign: {
            wtpTemplates: [],
            smsTemplates: [],
            callTemplates: [],
            pushNotificationTemplates: []
        }
    },

    mutations: {
        updateLoggedInUserSessions ( state, data ) {
            state.loggedInUserSessions.user = data
            state.loggedInUserSessions.localstoragepath = data.client_info.maa24;
            state.loggedInUserSessions.user_reference = data.token;
            state.loggedInUserSessions.permission_fks = data.ad28;
            state.loggedInUserSessions.client_info = data.client_info;
            state.AuthenticatedUserHeader[ 'Authorization' ] = "Bearer " + data.token;
            state.AuthenticatedUserHeader[ 'client' ] = data.client_info.maa1;
            state.AuthenticatedUserHeader[ 'user' ] = JSON.stringify( data );
        },

        updateBillingStateParam ( state, data ) {
            state.billingStateParam.showpaymenttwoloader = !state.billingStateParam.showpaymenttwoloader
            state.billingStateParam.paymentStatus = data
        },
        updateBillingStateParamRazorpay ( state, data ) {
            state.billingStateParamRazorpay.paymentStatus = data
        },
        addCampaignStatus ( state ) {
            state.newtempaltemodalstatus = !state.newtempaltemodalstatus;
        },

        updateMapLink ( state, data ) {
            state.maplink = data
        },
        updateMapLatitude ( state, data ) {
            state.latitude = data
        },
        updateMapLongitude ( state, data ) {
            state.longitude = data
        },
        resetMaplinkData ( state ) {
            state.maplink = null
            state.longitude = null
            state.latitude = null
        },
        addAdminUserListMutation ( state, data ) {
            state.addAdminUserList = JSON.parse( JSON.stringify( data ) )
        },
        addWhatsAppTemplatesMutation ( state, data ) {
            state.campaign.wtpTemplates = data
        },
        addSmsTemplatesMutation ( state, data ) {
            state.campaign.smsTemplates = data
        },
        addCallCampaignTemplatesMutation ( state, data ) {
            state.campaign.callTemplates = data
        },
        addPushNotificationsTemplatesMutation ( state, data ) {
            state.campaign.pushNotificationTemplates = data
        },
    },

    actions: {
        async checkLoginCredentials ( context ) {
            const client = localStorage && localStorage.client_vi_connect ? decrypt( localStorage.client_vi_connect ) : null;
            const user = localStorage && localStorage.user_vi_connect ? decrypt( localStorage.user_vi_connect ) : null;
            this.state.loggedInUserSessions.clientfk = client;
            this.state.loggedInUserSessions.userfk = user;
            if ( checkEmpty( client ) && checkEmpty( user ) ) {
                if ( !checkEmpty( this.state.loggedInUserSessions.user ) && !checkEmpty( this.state.loggedInUserSessions.user_reference ) ) {
                    return await apiService.loginCache( { ad1: user } ).then( ( data ) => {
                        context.commit( "updateLoggedInUserSessions", data.data )
                        return data
                    } );
                }
            }
        },

        updateBillingStateParamRazorPay ( context, payload ) {
            context.commit( "updateBillingStateParamRazorpay", payload )
        },
        updateBillingStateParam ( context, payload ) {
            context.commit( "updateBillingStateParam", payload )
        },
        addCampaignStatus ( context ) {
            context.commit( "addCampaignStatus" );
        },
        updateMapLink ( context, payload ) {
            context.commit( "updateMapLink", payload );
        },
        updateMapLatitude ( context, payload ) {
            context.commit( "updateMapLatitude", payload );
        },
        updateMapLongitude ( context, payload ) {
            context.commit( "updateMapLongitude", payload );
        },
        resetMaplinkData ( context ) {
            context.commit( "resetMaplinkData" );
        },
        addAdminUserListAction ( context, payload ) {
            context.commit( "addAdminUserListMutation", payload );
        },
        addWhatsAppTemplates ( context, payload ) {
            context.commit( "addWhatsAppTemplatesMutation", payload );
        },
        addSmsTemplates ( context, payload ) {
            context.commit( "addSmsTemplatesMutation", payload );
        },
        addCallCampaignTemplates ( context, payload ) {
            context.commit( "addCallCampaignTemplatesMutation", payload );
        },
        addPushNotificationsTemplates ( context, payload ) {
            context.commit( "addPushNotificationsTemplatesMutation", payload );
        }
    }
} );