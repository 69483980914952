<template>
  <div class="col-md-3 col-lg-2 header-navbar-outer">
    <div class="w-100">
      <div class="navbar-brand-1 me-0">
        <div class="d-flex justify-content-between align-items-center">
          
          <div class="logo-outer">
            <img src="/assets/images/vc-logo.png"  alt="" />
          </div>
          <div class="header-police-logo-outer">
            <div class="header-police-logo-inner">
              <img src="/assets/images/goa-police-logo.png" class="header-goa-logo" alt="goa police" />
              <div class="header-goa-label text-uppercase">goa police</div>
            </div>
          </div>
        </div>
      </div>
      <nav id="sidebarMenu" class="d-md-block collapse nav-navlink-outer">
        <div class="menubar-outer">
          <div class="menubar-submeanu-outer" v-for="(menuItem, headIndex) in sidebarMenu" :key="headIndex">
            <ul class="nav flex-column" :id="'header-' + headIndex">
              <li class="nav-item">
                <router-link v-if="menuItem.menu" class="nav-link" :to="menuItem.link || ''"><img :src="menuItem.img"
                    class="navlink-tab-icon" alt="menu" width="16" />{{ menuItem.menu }}</router-link>
                <button v-else-if="menuItem.menuHeader" class="btn btn-toggle align-items-center rounded collapsed"
                  data-bs-toggle="collapse" :data-bs-target="`#${menuItem.menuHeader.replaceAll(' ', '-').replaceAll('/', '-')}`" aria-expanded="false">
                  <img :src="menuItem.img" class="navlink-tab-icon" alt="" width="16" />{{ menuItem.menuHeader }}
                </button>
                <div v-if="menuItem.sidebarSubMenu" class="collapse" data-bs-parent="#sidebarMenu"
                  :id="menuItem.menuHeader.replaceAll(' ', '-').replaceAll('/', '-')">
                  <ul class="btn-toggle-nav fw-normal small">
                    <li v-for="(subMenu, index) in menuItem.sidebarSubMenu" :key="index">
                      <router-link class="nav-link" :to="subMenu.link"><div class="submenu-active-span">{{ subMenu.submenu }}</div></router-link>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div class="copyright-outer mt-auto">
        <div class="copyright-inner">
          <div class="pb-2">
            <img class="img-fluid-goa-sidebar w-100" alt="" src="/assets/images/white-GOA-electronics-01-01.png">
          </div>
          <div>
            <img src="/assets/images/login-samrudhh-logo.png" height="50" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      sidebarMenu: [
        {
          menu: "Dashboard",
          link: "/dashboard",
          img: "/assets/images/new-icon/menu-dashboard.svg",
        },
        {
          menu: "Branches list",
          link: "/branchlist",
          img: "/assets/images/new-icon/menu-guest.svg",
        },
        {
          menuHeader: "Users",
          img: "/assets/images/new-icon/menu-user.svg",
          sidebarSubMenu: [
            {
              submenu: "Add User",
              link: "/users/adduser"
            },
            {
              submenu: "Users List",
              link: "/users/userslist"
            },
            {
              submenu: "Permission Sets",
              link: "/users/permissionsets"
            },
            {
              submenu: "Import Users",
              link: "/users/importusers"
            }
          ]
        },
        {
          menuHeader: "Visitors",
          img: "/assets/images/new-icon/menu-guest.svg",
          sidebarSubMenu: [
            // {
            //   submenu: "Add Guest",
            //   link: "/guests/add"
            // },
            {
              submenu: "Current Visitors",
              link: "/visitors/current-visitor"
            },
            {
              submenu: "Visitors List",
              link: "/visitors/totalvisitor"
            },
            // {
            //   submenu: "Add Reports",
            //   link: "/guests/addreport"
            // },
          ]
        },
        {
          menuHeader: "Billing",
          img: "/assets/images/new-icon/menu-billing.svg",
          sidebarSubMenu: [
            {
              submenu: "Dashboard",
              link: "/billing/dashboard"
            },
            {
              submenu: "Tax Invoices",
              link: "/billing/invoice"
            },
            {
              submenu: "Account Statement",
              link: "/billing/statement"
            },
            {
              submenu: "Payment Receipts",
              link: "/billing/receipt"
            },
            {
              submenu: "TDS Payment",
              link: "/billing/tds"
            },

          ]
        },
        {
          menu: "Support",
          link: "/support/new",
          img: "/assets/images/new-icon/menu-campaign.svg",
        },
        // {
        //   menuHeader: "System Logs",
        //   img: "/assets/images/new-icon/menu-campaign.svg",
        //   sidebarSubMenu: [
        //     {
        //       submenu: "Web Bot Logs",
        //       link: "/users/adduser"
        //     }
        //   ]
        // },
      ]
    };
  },
};
</script>
